import produce from 'immer';
import { DraftEntityMap, EurekaDraft } from '../../@Types/Draft/Draft';
import { DraftEntityTypes } from '../../constants/Draft/DraftEntityTypes';
import { mapCondition } from '../ConditionEditorController/ConditionFunctions';
import { isMappableEntityData } from '../../@Types/Draft/DraftEntityData';

export function mapEurekaDraft(draft: EurekaDraft): EurekaDraft {
    return produce(draft, (draft) => {
        mapDraftEntityMap(draft.entityMap);
    });
}

export function mapDraftEntityMap(entityMap: DraftEntityMap): DraftEntityMap {
    return produce(entityMap, (entityMap) => {
        for (const entity of Object.values(entityMap)) {
            if (entity.type !== DraftEntityTypes.EUREKA) continue;
            const { data } = entity;
            if (data.condition) data.condition = mapCondition(data.condition);
            if (isMappableEntityData(data)) {
                data.entityMap = mapDraftEntityMap(data.entityMap);
            }
        }
    });
}
