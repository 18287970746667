import { EntityValue, Form } from '../../@Types/@Types';
import axiosInstance from '../../AxiosAPI';
import {
    EntityValueFilterTypes,
    EntityValuePathTypes,
    FormStepTypes,
} from '../../constants/FormStepTypes';
import produce from 'immer';
import { RootState } from '../../utils/_store';
import EntityPropertyTypes from '../../constants/Entities/EntityPropertyTypes';
import { mapCondition } from '../ConditionEditorController/ConditionFunctions';
import {
    ApiSelectorOptionTypes,
    ClassifierOptionTypes,
    EntityValueOptionTypes,
} from '../../constants/OptionTypes';
import { FormStep } from '../../@Types/FormTypes/FormStep';

/**
 * Function that load the form of a given entry
 * @param idEntry the id of the entry to fetch the form
 * @returns the form of the entry given
 */
export async function loadInternalForms(idProject: string): Promise<Form[]> {
    const response = await axiosInstance.get(
        `/projects/${idProject}/forms/internal`
    );
    return response?.data ?? [];
}

/**
 * Function that load the form of a given entry
 * @param apiKey the apiKey of the entry to fetch the form
 * @returns the form of the entry given
 */
export async function loadFormData(
    idProject: string,
    apiKey: string
): Promise<{ form: Form; entry: any }> {
    const [form, entry] = await Promise.all([
        axiosInstance.get(`/projects/${idProject}/forms/${apiKey}`),
        axiosInstance.get(`/projects/${idProject}/entries/${apiKey}`),
    ]);

    return {
        form: migrateFormData(form.data),
        entry: entry.data,
    };
}

export enum SIZES {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
}

export const SizesToNum = {
    [SIZES.LARGE]: 4,
    [SIZES.MEDIUM]: 2,
    [SIZES.SMALL]: 1,
};

export const migrateFormData = (form: Form): Form => {
    for (const idStep of Object.keys(form.steps)) {
        const step = form.steps[idStep];
        if (step.type === FormStepTypes.ENTITYVALUEPICKER) {
            //Guardar el value del path y el filtro siempre como un string de ids, todo revisar bien para otros casos que no sean entityRelationship
            for (const path of step.path) {
                if (path.type === EntityValuePathTypes.VALUE) {
                    path.idEntityValue = (path.idEntityValue as EntityValue)
                        ?._id
                        ? (path.idEntityValue as EntityValue)._id
                        : path.idEntityValue;
                }
            }
            for (const filter of step.filters) {
                if (filter.type === EntityValueFilterTypes.VALUE) {
                    //Migrar formularios porque siempre deberia ser un arreglo!
                    filter.value = filter.value.split(',');
                }
            }
        }
    }

    return form;
};

/**
 * Function that saves the form, sends an error if the form is not valid
 * @param idProject the id of the form's project
 * @param apiKey the form's entry apiKey
 * @param form the form to save
 */
export const saveForm = async (
    idProject: string,
    apiKey: string,
    pForm: Form,
    entities: RootState['site']['entities']
): Promise<void> => {
    const form = produce(pForm, (form) => {
        for (const step of Object.values(form.steps)) {
            mapStep(step, entities);
        }
    });
    await axiosInstance.post(`/projects/${idProject}/forms/${apiKey}`, form);
};

function mapStep(
    step: FormStep,
    entities: RootState['site']['entities']
): void {
    if (step.condition) step.condition = mapCondition(step.condition);
    if (step.type === FormStepTypes.ENTITYVALUEPICKER) {
        const entity = entities[step.idEntity];
        //Guardar el value del filtro y el path siempre como un string de ids, todo revisar bien para otros casos que no sean entityRelationship
        for (const path of step.path) {
            if (path.type === EntityValuePathTypes.VALUE) {
                path.idEntityValue =
                    typeof path.idEntityValue !== 'string'
                        ? path.idEntityValue?._id ?? null
                        : path.idEntityValue;
            }
        }
        for (const filter of step.filters) {
            const property = entity.steps[filter.idProperty];
            if (
                filter.type === EntityValueFilterTypes.VALUE &&
                property.type === EntityPropertyTypes.ENTITY_RELATIONSHIP
            ) {
                filter.value = filter.value
                    .map((val: EntityValue) => val?._id ?? val)
                    .join(',');
            }
        }
        for (const option of Object.values(step.options)) {
            if (option.type !== EntityValueOptionTypes.HIDE && option.condition)
                option.condition = mapCondition(option.condition);
        }
    } else if (step.type === FormStepTypes.SELECTOR) {
        for (const option of step.options) {
            if (option.condition)
                option.condition = mapCondition(option.condition);
        }
    } else if (step.type === FormStepTypes.CLASSIFIER_SELECTOR) {
        for (const option of Object.values(step.options)) {
            if (option.type !== ClassifierOptionTypes.HIDE && option.condition)
                option.condition = mapCondition(option.condition);
        }
    } else if (step.type === FormStepTypes.API_SELECTOR) {
        for (const option of Object.values(step.options)) {
            if (option.type !== ApiSelectorOptionTypes.HIDE && option.condition)
                option.condition = mapCondition(option.condition);
        }
    } else if (step.type === FormStepTypes.MAPPER) {
        for (const subStep of Object.values(step.steps)) {
            mapStep(subStep, entities);
        }
    }
}

export async function loadFormHistory(
    idProject: string,
    idForm: string
): Promise<Form> {
    const resp = await axiosInstance.get(
        `/projects/${idProject}/forms/history/${idForm}`
    );
    return resp.data;
}

export default {
    loadFormData,
    saveForm,
    loadFormHistory,
};
