import { MenuItem } from '@material-ui/core';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../RoundedSelect/RoundedSelect';
import React from 'react';
import EntityPropertyTypes from '../../../constants/Entities/EntityPropertyTypes';
import {
    AgentPicker,
    ClientPicker,
    TextInput,
} from '../../../@Types/EntityTypes/EntityProperty';
import NotificationTypes from '../../../constants/NotificationTypes';
import styles from './RoundedEntityPropertyPicker.module.css';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import WhatsappIcon from '../../../Icons/WhatsappIcon';
import { PropertyComponent } from './RoundedEntityPropertyPicker';
import { Entity } from '../../../@Types/@Types';

interface RoundedEntityNotificationPickerProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    entity: Entity;
    /** Currently selected entityProperty id */
    value: string | undefined;
    /** function called when value changes */
    handleUpdate: (idProperty: string) => void;
}
const menuStyle = {
    paddingLeft: 0,
};

function RoundedEntityNotificationPicker({
    value,
    entity,
    handleUpdate,
    ...others
}: RoundedEntityNotificationPickerProps): JSX.Element {
    return (
        <RoundedSelect
            value={value}
            paddingLeft={0}
            handleUpdate={(event): void => {
                handleUpdate(event.target.value);
            }}
            {...others}
        >
            {(
                Object.values(entity?.steps ?? {}).filter(
                    (property) =>
                        property.type === EntityPropertyTypes.CLIENTPICKER ||
                        property.type === EntityPropertyTypes.AGENTPICKER
                ) as (ClientPicker | AgentPicker)[]
            ).map((property) => (
                <MenuItem
                    key={property.id}
                    value={property.id}
                    style={menuStyle}
                >
                    <PropertyComponent property={property} />
                </MenuItem>
            ))}
            {(
                Object.values(entity?.steps ?? {}).filter(
                    (property) =>
                        property.type === EntityPropertyTypes.TEXTINPUT &&
                        property.notificationType
                ) as TextInput[]
            ).map((property) => {
                switch (property.notificationType) {
                    case NotificationTypes.EMAIL:
                        return (
                            <MenuItem
                                style={menuStyle}
                                value={property.id}
                                key={NotificationTypes.EMAIL}
                            >
                                <div className={styles.property}>
                                    <div className={styles.mailIcon}>
                                        <MailOutlineRoundedIcon fontSize="inherit" />
                                    </div>
                                    {property.label}
                                </div>
                            </MenuItem>
                        );
                    case NotificationTypes.WHATSAPP:
                        return (
                            <MenuItem
                                style={menuStyle}
                                key={property.id}
                                value={property.id}
                            >
                                <div className={styles.property}>
                                    <div className={styles.whatsappIcon}>
                                        <WhatsappIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 32,
                                                width: 32,
                                            }}
                                        />
                                    </div>
                                    {property.label}
                                </div>
                            </MenuItem>
                        );
                    default:
                        return <div></div>;
                        break;
                }
            })}
        </RoundedSelect>
    );
}
export default RoundedEntityNotificationPicker;
