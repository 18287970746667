import React from 'react';
import { NotificationRecipient } from '../../../../../@Types/FlowTypes/NodeTypes/CommunicationNode';
import RecipientTypeSelector from './RecipientTypeSelector/RecipientTypeSelector';
import styles from './RecipientComponent.module.css';
import NotificationRecipientTypes from '../../../../../constants/Flows/NotificationRecipientTypes';
import DeleteIcon from '@material-ui/icons/Delete';
import RoundedTextField from '../../../../../shared/RoundedTextField/RoundedTextField';
import RoundedClientNotificationPicker from '../../../../../shared/@Pickers/RoundedClientPropertyPicker/RoundedClientNotificationPicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
import RoundedEntityNotificationPicker from '../../../../../shared/@Pickers/RoundedEntityPropertyPicker/RoundedEntityNotificationPicker';
import EntityPropertyTypes from '../../../../../constants/Entities/EntityPropertyTypes';
import RoundedPhoneInput from '../../../../../shared/RoundedPhoneInput/RoundedPhoneInput';

interface RecipientComponentProps {
    recipient: NotificationRecipient;
    /** Function called when a value needs to update */
    handleUpdate: Function;
    /** Function called when the recipient is deleted*/
    handleDelete: Function;
}
function RecipientComponent({
    recipient,
    handleUpdate,
    handleDelete,
}: RecipientComponentProps): JSX.Element {
    const entity = useSelector(
        (state: RootState) =>
            state.site.entities[state.site.organization?.idCompanyEntity ?? '']
    );
    const pickCompany =
        entity &&
        Object.values(entity.steps).find(
            (step) =>
                step.type === EntityPropertyTypes.CLIENTPICKER ||
                step.type === EntityPropertyTypes.AGENTPICKER ||
                (step.type === EntityPropertyTypes.TEXTINPUT &&
                    step.notificationType)
        ) !== undefined;

    const renderValue = (): JSX.Element => {
        switch (recipient.type) {
            case NotificationRecipientTypes.EMAIL:
                return (
                    <div className={styles.valueContainer}>
                        <RoundedTextField
                            label="Correo"
                            value={recipient.email}
                            onChange={(e): void => {
                                handleUpdate({
                                    ...recipient,
                                    email: e.target.value,
                                });
                            }}
                            height="40px"
                        />
                    </div>
                );
            case NotificationRecipientTypes.WHATSAPP:
                return (
                    <div className={styles.valueContainer}>
                        <RoundedPhoneInput
                            height="40px"
                            label="Teléfono"
                            placeholder="0123456789"
                            value={recipient.cel}
                            onChange={(cel): void => {
                                handleUpdate({
                                    ...recipient,
                                    cel,
                                });
                            }}
                        />
                    </div>
                );
            case NotificationRecipientTypes.CLIENT:
                return (
                    <div className={styles.clientContainer}>
                        <RoundedClientNotificationPicker
                            value={recipient.idProperty}
                            handleUpdate={(idProperty): void => {
                                handleUpdate({
                                    ...recipient,
                                    idProperty,
                                });
                            }}
                        />
                    </div>
                );
            case NotificationRecipientTypes.COMPANY:
                if (!entity) return <div></div>;
                const step = entity.steps[recipient.idProperty];
                return (
                    <React.Fragment>
                        <div className={styles.clientContainer}>
                            <RoundedEntityNotificationPicker
                                entity={entity}
                                value={recipient.idProperty}
                                handleUpdate={(idProperty): void => {
                                    handleUpdate({
                                        ...recipient,
                                        idProperty,
                                    });
                                }}
                            />
                        </div>
                        {step &&
                            step.type === EntityPropertyTypes.CLIENTPICKER && (
                                <div className={styles.clientContainer}>
                                    <RoundedClientNotificationPicker
                                        value={recipient.idNestedProperty}
                                        handleUpdate={(
                                            idNestedProperty
                                        ): void => {
                                            handleUpdate({
                                                ...recipient,
                                                idNestedProperty,
                                            });
                                        }}
                                    />
                                </div>
                            )}
                    </React.Fragment>
                );
            default:
                return <div></div>;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.bullet} />
            <div className={styles.typeContainer}>
                <RecipientTypeSelector
                    value={recipient.type}
                    handleUpdate={(type: NotificationRecipientTypes): void => {
                        handleUpdate({ type });
                    }}
                    pickCompany={pickCompany}
                    fullWidth
                />
            </div>
            {renderValue()}
            <div className={styles.removeContainer}>
                <div
                    className={styles.removeConditionContainer}
                    onClick={(): void => {
                        handleDelete();
                    }}
                >
                    <DeleteIcon fontSize="inherit" />
                </div>
            </div>
        </div>
    );
}

export default RecipientComponent;
