import styles from './PropertyMenu.module.css';
import React from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TitleIcon from '@material-ui/icons/Title';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SubjectIcon from '@material-ui/icons/Subject';
import DescriptionIcon from '@material-ui/icons/Description';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import { nanoid } from 'nanoid';
import PropertyTypes from '../../../../constants/Entities/EntityPropertyTypes';
import {
    EntityProperty,
    EntitySelector,
} from '../../../../@Types/EntityTypes/EntityProperty';
import EntityIcon from '../../../../shared/SmartIcons/EntityIcon';
import EntityPropertyTypes from '../../../../constants/Entities/EntityPropertyTypes';
import { MapperStyleTypes } from '@arquimedes.co/eureka-forms/dist/constants/FormStepTypes';
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import { PropertyLocation } from '../../../../@Types/EntityTypes/Entity';
import {
    calcLocationSteps,
    useEntityEditorSelector,
} from '../EntityReducerFunctions';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import { useAppSelector } from '../../../../hooks';

interface PropertyMenuProps {
    handleClose?: Function;
    handleAdd?: (property: EntityProperty) => void;
    anchorRef?: any;
    location?: PropertyLocation | null; //Null if main create btn
    zIndex?: number;
}

export default function PropertyMenu({
    handleClose,
    anchorRef,
    handleAdd,
    location,
    zIndex = 3,
}: PropertyMenuProps): JSX.Element {
    if (!handleClose || !handleAdd || !anchorRef || !location) {
        return <div></div>;
    }

    const idClientEntity = useAppSelector(
        (state) => state.site.organization?.idClientEntity
    );

    const editorInfo = useEntityEditorSelector((editorInfo) => editorInfo);

    const isClient = idClientEntity === editorInfo.data._id;

    const calcProperties = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];
        if (!isClient) {
            elements.push(
                <div
                    className={styles.option}
                    style={{ borderTop: 'none' }}
                    key="NAME"
                    onClick={(): void => {
                        handleAdd({
                            id: PropertyTypes.NAME + '-' + nanoid(),
                            type: PropertyTypes.NAME,
                            label: 'Nombre',
                            description: '',
                            required: false,
                            editable: true,
                        });
                    }}
                >
                    <div className={styles.titleIcon}>
                        <TitleIcon fontSize="inherit" />
                    </div>
                    <div className={styles.titleOptionLabel}>Nombre</div>
                </div>
            );
        }
        elements.push(
            <div
                className={styles.option}
                style={{ borderTop: 'none' }}
                key="TITLE"
                onClick={(): void => {
                    handleAdd({
                        id: PropertyTypes.TITLE + '-' + nanoid(),
                        type: PropertyTypes.TITLE,
                        title: 'Titulo',
                        description: '',
                        required: false,
                        editable: true,
                    });
                }}
            >
                <div className={styles.titleIcon}>
                    <TitleIcon fontSize="inherit" />
                </div>
                <div className={styles.titleOptionLabel}>Titulo</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="TEXTINPUT"
                onClick={(): void => {
                    handleAdd({
                        id: PropertyTypes.TEXTINPUT + '-' + nanoid(),
                        type: PropertyTypes.TEXTINPUT,
                        label: 'Campo',
                        description: '',
                        required: false,
                        editable: true,
                        size: 2,
                    });
                }}
            >
                <div className={styles.textInputContainer}>A..</div>
                <div className={styles.optionLabel}>Campo de Texto</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="SELECTOR"
                onClick={(): void => {
                    const defaultValue: EntitySelector = {
                        id: PropertyTypes.SELECTOR + '-' + nanoid(),
                        type: PropertyTypes.SELECTOR,
                        label: 'Opciones',
                        description: '',
                        required: false,
                        editable: true,
                        searchable: false,
                        options: [],
                        size: 1,
                    };
                    if (location === null) {
                        defaultValue.maxSize = 1;
                    }
                    handleAdd(defaultValue);
                }}
            >
                <div className={styles.selectInputContainer}>
                    <ArrowDropDownIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Opciones</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="DATEPICKER"
                onClick={(): void => {
                    handleAdd({
                        id: PropertyTypes.DATEPICKER + '-' + nanoid(),
                        type: PropertyTypes.DATEPICKER,
                        label: 'Fecha',
                        description: '',
                        pickTime: false,
                        required: false,
                        editable: true,
                        size: 1,
                    });
                }}
            >
                <div className={styles.dateInputContainer}>
                    <CalendarTodayRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Campo de Fecha</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="TIMEPICKER"
                onClick={(): void => {
                    handleAdd({
                        id: PropertyTypes.TIMEPICKER + '-' + nanoid(),
                        type: PropertyTypes.TIMEPICKER,
                        label: 'Tiempo',
                        description: '',
                        required: false,
                        pickDays: true,
                        pickHours: true,
                        pickMinutes: true,
                        editable: true,
                        size: 1,
                    });
                }}
            >
                <div className={styles.dateInputContainer}>
                    <ScheduleRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Campo de Tiempo</div>
            </div>
        );
        if (!isClient) {
            elements.push(
                <div
                    className={styles.option}
                    key="AGENTPICKER"
                    onClick={(): void => {
                        handleAdd({
                            id: PropertyTypes.AGENTPICKER + '-' + nanoid(),
                            type: PropertyTypes.AGENTPICKER,
                            label: 'Agente',
                            description: '',
                            required: false,
                            editable: true,
                            multiple: false,
                            size: 1,
                        });
                    }}
                >
                    <div className={styles.agentInputContainer}>
                        <PersonRoundedIcon fontSize="inherit" />
                    </div>
                    <div className={styles.optionLabel}>Agente</div>
                </div>
            );
        }
        if (!isClient) {
            elements.push(
                <div
                    className={styles.option}
                    key="CLIENTPICKER"
                    onClick={(): void => {
                        handleAdd({
                            id: PropertyTypes.CLIENTPICKER + '-' + nanoid(),
                            type: PropertyTypes.CLIENTPICKER,
                            label: 'Cliente',
                            description: '',
                            required: false,
                            editable: true,
                            multiple: false,
                            size: 1,
                        });
                    }}
                >
                    <div className={styles.agentInputContainer}>
                        <PersonRoundedIcon fontSize="inherit" />
                    </div>
                    <div className={styles.optionLabel}>Cliente</div>
                </div>
            );
        }
        elements.push(
            <div
                className={styles.option}
                key="TEXTAREA"
                onClick={(): void => {
                    handleAdd({
                        id: PropertyTypes.TEXTAREA + '-' + nanoid(),
                        type: PropertyTypes.TEXTAREA,
                        label: 'Observaciones',
                        description: '',
                        required: false,
                        editable: true,
                        hasTextEditor: false,
                    });
                }}
            >
                <div className={styles.textAreaContainer}>
                    <SubjectIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Observaciones</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="CHECKBOX"
                onClick={(): void => {
                    handleAdd({
                        id: PropertyTypes.CHECKBOX + '-' + nanoid(),
                        type: PropertyTypes.CHECKBOX,
                        label: 'Campo',
                        description: '',
                        required: false,
                        editable: true,
                        size: 1,
                    });
                }}
            >
                <div className={styles.checkBoxIcon}>
                    <CheckBoxRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>CheckBox</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="SEPARATOR"
                onClick={(): void => {
                    handleAdd({
                        id: PropertyTypes.SEPARATOR + '-' + nanoid(),
                        type: PropertyTypes.SEPARATOR,
                        transparent: false,
                    });
                }}
            >
                <div className={styles.separatorContainer}>
                    <NavigateBeforeRoundedIcon fontSize="inherit" />
                    <div className={styles.separatorSeparator}></div>
                    <NavigateNextRoundedIcon fontSize="inherit" />
                </div>

                <div className={styles.optionLabel}>Separador</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="FILEUPLOAD"
                onClick={(): void => {
                    handleAdd({
                        id: PropertyTypes.FILEUPLOAD + '-' + nanoid(),
                        type: PropertyTypes.FILEUPLOAD,
                        label: 'Archivos',
                        description: '',
                        editable: true,
                        required: false,
                    });
                }}
            >
                <div className={styles.fileInputContainer}>
                    <DescriptionIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Subir Archivos</div>
            </div>
        );
        for (const relationship of editorInfo.data.relationships ?? []) {
            const steps = calcLocationSteps(editorInfo, location);
            const idStep =
                steps && steps.length > 0
                    ? steps[steps.length - 1]
                    : location.idRootStep;

            const dependencies = editorInfo.stepDependencies[idStep];
            if (
                !relationship.idRelationshipSteps.find((idStep: string) =>
                    dependencies.previousSteps.includes(idStep)
                )
            ) {
                elements.push(
                    <div
                        className={styles.option}
                        key={'ENTITY_RELATIONSHIP' + relationship.idEntity}
                        onClick={(): void => {
                            handleAdd({
                                id:
                                    PropertyTypes.ENTITY_RELATIONSHIP +
                                    '-' +
                                    nanoid(),
                                type: PropertyTypes.ENTITY_RELATIONSHIP,
                                idEntity: relationship.idEntity,
                                label: relationship.name,
                                description: relationship.description,
                                multiple: relationship.multiple,
                                editable: true,
                                required: !!relationship.required,
                                size: 2,
                                style: {
                                    type: 'INPUT',
                                },
                                filters: [],
                            });
                        }}
                    >
                        <div className={styles.entityIcon}>
                            <EntityIcon
                                fill={'var(--secondary)'}
                                idEntity={relationship.idEntity}
                            />
                        </div>
                        <div className={styles.optionLabel}>
                            {relationship.name}
                        </div>
                    </div>
                );
            }
        }

        elements.push(
            <div
                className={styles.option}
                key="MAPPER"
                onClick={(): void => {
                    handleAdd({
                        id: EntityPropertyTypes.MAPPER + '-' + nanoid(),
                        type: EntityPropertyTypes.MAPPER,
                        label: 'Elementos',
                        description: '',
                        addBtnLabel: 'Agregar',
                        required: false,
                        unitLabel: 'Elemento',
                        creatable: true,
                        style: MapperStyleTypes.PILL,
                        rootSteps: [],
                        steps: {},
                    });
                }}
            >
                <div className={styles.mapperIcon}>
                    <PlaylistAddRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Lista de Elementos</div>
            </div>
        );

        return elements;
    };

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={(): void => {
                handleClose();
            }}
        >
            <Popper
                open={true}
                anchorEl={anchorRef.current}
                placement={'bottom'}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        priority: ['top', 'bottom', 'left', 'right'],
                        boundariesElement: 'viewport',
                        padding: 20,
                    },
                }}
                style={{ zIndex }}
            >
                <div
                    className={styles.container}
                    style={{
                        borderRadius: 10,
                    }}
                >
                    {calcProperties()}
                </div>
            </Popper>
        </ClickAwayListener>
    );
}
