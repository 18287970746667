import styles from './ListApiStep.module.css';
import {
    ConversationApiOption,
    ListApiStep,
    NestedConversationApiOption,
} from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch } from 'react-redux';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import EntityPropertyTypes from '../../../../../../constants/Entities/EntityPropertyTypes';
import { ClientInfoTypes } from '../../../../../../constants/ClientInfoTypes';
import RoundedClientPropertyPicker from '../../../../../../shared/@Pickers/RoundedClientPropertyPicker/RoundedClientPropertyPicker';
import Toggle from '../../../../../../shared/Toggle/Toggle';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ClientPropertyTypes from '../../../../../../constants/ClientPropertyTypes';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';
import ConversationStepTypes from '../../../../../../constants/Conversations/ConversationStepTypes';
import {
    ApiRequest,
    ApiRequestOption,
    compareRequests,
} from '../../../../../../@Types/ApiRequest';
import { fetchApiRequest } from '../../../../../../controllers/ApiRequestService';
import { useAppSelector } from '../../../../../../hooks';
import Loader from '../../../../../../shared/Loader/Loader';
import RoundedRadio from '../../../../../../shared/RoundedRadio/RoundedRadio';
import Conditions from '../../Condition/Condition';
import { ApiOptionTypes } from '../../../../../../constants/ApiOptionTypes';
import produce from 'immer';
import FilterIcon from '../../../../../../Icons/FilterIcon';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ApiRequestEditor from '../../../../../../shared/ApiRequestEditor/ApiRequestEditor';
import ConditionTypes from '../../../../../../constants/Conditions/ConditionTypes';
import RoundedClassifierPicker from '../../../../../../shared/@Pickers/RoundedClassifierPicker/RoundedClassifierPicker';
import { Menu, MenuItem } from '@material-ui/core';
import DisabledIcon from '../../../../../../Icons/DisabledIcon';

interface ListApiStepProps {
    step: ListApiStep;
}

function ListApiStepComponent({
    step,
    showClientInfoType = true,
}: ListApiStepProps & {
    showClientInfoType?: boolean;
}): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);
    const idOrganization = useAppSelector(
        (state) => state.site.organization!.idOrganization
    );
    const btnRef = useRef<HTMLDivElement>(null);
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [showMenu, setShowMenu] = useState(false);
    const [showConditions, setShowConditions] = useState<undefined | string>();
    const [request, setRequest] = useState<ApiRequest | undefined>();
    const [options, setOptions] = useState<{
        ids: string[];
        options: Record<string, ApiRequestOption>;
    } | null>(null);

    const isIntegration = step.type !== ConversationStepTypes.LIST_API_STEP;
    const loadData = async (): Promise<void> => {
        setRequest(step.request);
        try {
            const data = await fetchApiRequest(step.request, idOrganization);
            const options: Record<string, ApiRequestOption> = {};
            const ids: string[] = [];
            for (const item of data) {
                options[item.id] = item;
                ids.push(item.id);
            }
            setOptions({ options, ids });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!request && step.request) loadData();
        else if (step.request && !compareRequests(step.request, request)) {
            try {
                clearTimeout(timer);
                setTimer(
                    setTimeout(() => {
                        loadData();
                        setTimer(undefined);
                    }, 1000)
                );
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
    }, [step.request]);

    useEffect(() => {
        return (): void => {
            try {
                clearTimeout(timer);
                // eslint-disable-next-line no-empty
            } catch (e) {}
        };
    }, []);

    const allowedOptions = useMemo(() => {
        if (!options) return [];
        return options.ids.filter((idOption) => !step.options[idOption]);
    }, [options, step.options]);

    const mapOptions = (): JSX.Element => {
        if (!options)
            return (
                <div className={styles.loaderContainer}>
                    <Loader size={50} />
                </div>
            );
        return (
            <React.Fragment>
                <div className={styles.optionsList}>
                    {options.ids
                        .filter((idOption) => step.options[idOption])
                        .map((idOption) => {
                            const option = step.options[idOption];
                            return (
                                <div
                                    className={styles.optionContainer}
                                    key={idOption}
                                >
                                    <div
                                        className={
                                            styles.optionContentContainer
                                        }
                                    >
                                        <div
                                            className={styles.optionRadio}
                                            title={'Seleccionar Opción'}
                                        >
                                            <RoundedRadio
                                                checked={
                                                    idOption ===
                                                    step.selectedOption
                                                }
                                                onChange={(): void => {
                                                    dispatch(
                                                        updateStep({
                                                            ...step,
                                                            selectedOption:
                                                                idOption,
                                                        })
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className={styles.optionLabel}>
                                            {options.options[idOption].label}
                                        </div>
                                        <div className={styles.btnsContainer}>
                                            <div
                                                className={
                                                    option.type ===
                                                    ApiOptionTypes.NESTED
                                                        ? styles.hideBtn
                                                        : styles.showBtn
                                                }
                                                onClick={(): void => {
                                                    const newStep = produce(
                                                        step,
                                                        (step) => {
                                                            step.options[
                                                                idOption
                                                            ] =
                                                                option.type ===
                                                                ApiOptionTypes.HIDE
                                                                    ? {
                                                                          type: ApiOptionTypes.NESTED,
                                                                          steps: [],
                                                                      }
                                                                    : {
                                                                          type: ApiOptionTypes.HIDE,
                                                                      };
                                                        }
                                                    );
                                                    dispatch(
                                                        updateStep(newStep)
                                                    );
                                                }}
                                            >
                                                <DisabledIcon
                                                    style={{
                                                        height: 20,
                                                        width: 20,
                                                    }}
                                                    fill="inherit"
                                                />
                                            </div>
                                            {option.type !==
                                                ApiOptionTypes.HIDE && (
                                                <div
                                                    className={
                                                        styles.conditionsBtn
                                                    }
                                                    onClick={(): void => {
                                                        setShowConditions(
                                                            idOption
                                                        );
                                                    }}
                                                >
                                                    <FilterIcon
                                                        style={{
                                                            height: 18,
                                                            width: 18,
                                                            marginTop: 2,
                                                        }}
                                                        fill="inherit"
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className={styles.deleteBtn}
                                                onClick={(): void => {
                                                    const newStep = produce(
                                                        step,
                                                        (step) => {
                                                            delete step.options[
                                                                idOption
                                                            ];
                                                            if (
                                                                step.selectedOption ===
                                                                idOption
                                                            ) {
                                                                step.selectedOption =
                                                                    null;
                                                            }
                                                        }
                                                    );
                                                    dispatch(
                                                        updateStep(newStep)
                                                    );
                                                }}
                                            >
                                                <DeleteRoundedIcon fontSize="inherit" />
                                            </div>
                                        </div>
                                    </div>
                                    {option.type !== ApiOptionTypes.HIDE && (
                                        <div
                                            className={
                                                styles.optionClassifierContainer
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.classifierNameLbl
                                                }
                                            >
                                                Clasificador:
                                            </div>
                                            <div
                                                className={
                                                    styles.optionClassifierInput
                                                }
                                            >
                                                <RoundedClassifierPicker
                                                    fullWidth
                                                    alwaysHot
                                                    height="32px"
                                                    label="Clasificador"
                                                    value={
                                                        option.classifier
                                                            ? [
                                                                  option.classifier,
                                                              ]
                                                            : []
                                                    }
                                                    handleUpdate={(
                                                        classifiers
                                                    ): void => {
                                                        const newStep = produce(
                                                            step,
                                                            (step) => {
                                                                step.options[
                                                                    idOption
                                                                ] = {
                                                                    ...option,
                                                                    classifier:
                                                                        classifiers?.[0],
                                                                };
                                                            }
                                                        );
                                                        dispatch(
                                                            updateStep(newStep)
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
                {showMenu && (
                    <Menu
                        anchorEl={btnRef.current}
                        open={true}
                        onClose={(): void => {
                            setShowMenu(false);
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        MenuListProps={{
                            style: { minWidth: 130 },
                        }}
                    >
                        {allowedOptions?.map((idOption) => (
                            <MenuItem
                                onClick={(): void => {
                                    dispatch(
                                        updateStep(
                                            produce(step, (tempStep) => {
                                                tempStep.options[idOption] = {
                                                    type: ApiOptionTypes.NESTED,
                                                    steps: [],
                                                };
                                            })
                                        )
                                    );
                                    setShowMenu(false);
                                }}
                                key={idOption}
                            >
                                <div className={styles.option}>
                                    {options.options[idOption].label}
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                )}
                {allowedOptions.length > 0 && (
                    <div
                        className={styles.addOption}
                        onClick={(): void => {
                            setShowMenu(true);
                        }}
                        ref={btnRef}
                    >
                        <div className={styles.addIconContainer}>
                            <AddRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.addOptionLbL + ' noselect'}>
                            Agregar Opción
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    };

    const conditionOption = showConditions && step.options[showConditions];

    return (
        <React.Fragment>
            {conditionOption && isNestedOption(conditionOption) && (
                <Conditions
                    handleClose={(): void => {
                        setShowConditions(undefined);
                    }}
                    condition={conditionOption.condition}
                    title={step.name}
                    handleUpdate={(condition): void => {
                        dispatch(
                            updateStep(
                                produce(step, (newStep) => {
                                    const option =
                                        newStep.options[showConditions];
                                    if (option && isNestedOption(option)) {
                                        option.condition = condition;
                                    }
                                })
                            )
                        );
                    }}
                    idStep={step.id}
                />
            )}
            <div className={styles.headerContainer}>
                <RoundedTextField
                    label="Encabezado"
                    multiline
                    maxLength={60}
                    value={step.header}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, header: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.container}>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        placeholder={'Mensaje'}
                        type={TextEditorTypes.WHATSAPP}
                        idEditor={`Conversation-${apiKey}-${step.id}`}
                        payload={{ draft: step.message }}
                        onChange={({ draft: message }): void => {
                            if (!message) return;
                            dispatch(updateStep({ ...step, message }));
                        }}
                        media={false}
                        context={{ client: true }}
                        conditions={{
                            types: BaseConversationPayloadConditionTypes,
                            context: {
                                conversation: {
                                    idStep: step.id,
                                },
                            },
                        }}
                        minHeight={144}
                        maxHeight={400}
                        required
                        maxLength={4096}
                    />
                </div>
            </div>
            <div className={styles.footerContainer}>
                <RoundedTextField
                    label="Pie"
                    multiline
                    maxLength={60}
                    value={step.footer}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, footer: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.btnLblContainer}>
                <RoundedTextField
                    label="Botón"
                    value={step.label}
                    maxLength={20}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({
                                ...step,
                                label: e.target.value,
                            })
                        );
                    }}
                ></RoundedTextField>
            </div>
            {/* <div className={styles.toggleLabelContainer}>
                Mostrar en la Traza:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.showInTrace}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({ ...step, showInTrace: checked })
                            );
                        }}
                    />
                </div>
            </div> */}
            {showClientInfoType && (
                <div className={styles.clientContainer}>
                    <div className={styles.toggleLabelContainer}>
                        <div className={styles.clientLbl}>
                            Información del Cliente:
                        </div>
                        <div className={styles.toggleContainer}>
                            <Toggle
                                checked={
                                    step.clientInfoType !== undefined ||
                                    step.idClientProperty !== undefined
                                }
                                onChange={(checked: boolean): void => {
                                    const tempStep = checked
                                        ? {
                                              ...step,
                                              clientInfoType:
                                                  ClientInfoTypes.NAME,
                                              recurring: checked,
                                          }
                                        : {
                                              ...step,
                                              clientInfoType: undefined,
                                              idClientProperty: undefined,
                                              recurring: undefined,
                                          };
                                    dispatch(updateStep(tempStep));
                                }}
                            />
                        </div>
                    </div>
                    {(step.clientInfoType !== undefined ||
                        step.idClientProperty !== undefined) && (
                        <React.Fragment>
                            <div className={styles.clientInfoContainer}>
                                <div className={styles.clientInfoLabel}>
                                    Tipo:{' '}
                                </div>
                                <div
                                    className={
                                        styles.clientInfoSelectorContainer
                                    }
                                >
                                    <RoundedClientPropertyPicker
                                        height="31px"
                                        fullWidth
                                        label={''}
                                        MenuProps={{ disablePortal: true }}
                                        value={
                                            step.clientInfoType ??
                                            step.idClientProperty
                                        }
                                        propertyTypes={[
                                            ClientPropertyTypes.CLIENT_INFO_TYPE,
                                            EntityPropertyTypes.TEXTINPUT,
                                        ]}
                                        handleUpdate={(idProperty): void => {
                                            if (
                                                Object.values(
                                                    ClientInfoTypes
                                                ).includes(
                                                    idProperty as ClientInfoTypes
                                                )
                                            ) {
                                                dispatch(
                                                    updateStep({
                                                        ...step,
                                                        clientInfoType:
                                                            idProperty as ClientInfoTypes,
                                                        idClientProperty:
                                                            undefined,
                                                    })
                                                );
                                            } else {
                                                dispatch(
                                                    updateStep({
                                                        ...step,
                                                        clientInfoType:
                                                            undefined,
                                                        idClientProperty:
                                                            idProperty,
                                                    })
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.recurringLabelContainer}>
                                <div className={styles.clientLbl}>
                                    Preguntar Siempre:
                                </div>
                                <div className={styles.toggleContainer}>
                                    <Toggle
                                        checked={step.recurring === true}
                                        onChange={(checked: boolean): void => {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    recurring: checked,
                                                })
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
            {step.request && !isIntegration && (
                <div className={styles.requestEditorContainer}>
                    <ApiRequestEditor
                        idEditor={step.id}
                        request={step.request}
                        handleUpdate={(request): void => {
                            dispatch(
                                updateStep({
                                    ...step,
                                    request,
                                })
                            );
                        }}
                        context={{
                            conversation: {
                                idStep: step.id,
                            },
                        }}
                        conditions={{
                            types: [ConditionTypes.CONVERSATION_STEP],
                            context: {
                                conversation: {
                                    idStep: step.id,
                                },
                            },
                        }}
                    />
                </div>
            )}

            {step.type === ConversationStepTypes.LIST_API_STEP && (
                <>
                    <div className={styles.optionsContainer}>
                        <label className={styles.lblCont}>Opciones:</label>
                    </div>
                    {mapOptions()}
                </>
            )}
        </React.Fragment>
    );
}

export default ListApiStepComponent;

function isNestedOption(
    option: ConversationApiOption
): option is NestedConversationApiOption {
    return option.type === ApiOptionTypes.NESTED;
}
