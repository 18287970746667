import React from 'react';
import { ConditionTypes } from '../../../constants/Conditions/ConditionTypes';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import styles from './RoundedConditionPicker.module.css';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import CallSplitRoundedIcon from '@material-ui/icons/CallSplitRounded';
import TitleIcon from '@material-ui/icons/Title';
import SubjectIcon from '@material-ui/icons/Subject';
import { MenuItem } from '@material-ui/core';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../RoundedSelect/RoundedSelect';
import EntryIcon from '../../../Icons/EntryIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import EntityIcon from '../../SmartIcons/EntityIcon';
import { ProjectEntity } from '../../../@Types/Project';
import WhatsappIcon from '../../../Icons/WhatsappIcon';
import { ConditionContext } from '../../ConditionEditor/ConditionEditor';
import FlowIcon from '../../../Icons/FlowIcon';
import { useAppSelector, useCurrentProject } from '../../../hooks';
import BrainIcon from '../../../Icons/BrainIcon';

interface RoundedConditionPickerProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    context: ConditionContext;
    /** Currently selected value */
    value: ConditionTypes | string | undefined;
    /** The posible properties to select */
    types?: ConditionTypes[];
    /** function called when value changes */
    handleUpdate: (property: ConditionTypes | string) => void;
}
function RoundedConditionPicker({
    value,
    types = [],
    context,
    handleUpdate,
    ...others
}: RoundedConditionPickerProps): JSX.Element {
    const project = useCurrentProject();
    const organization = useAppSelector((state) => state.site.organization);
    const entities = useAppSelector((state) => state.site.entities);

    const previousSteps = useSelector(
        (state: RootState) =>
            state.conversationEditor.stepDependencies[
                context.conversation?.idStep ?? ''
            ]?.previousSteps
    );

    return (
        <RoundedSelect
            value={value === 'DEFAULT' ? '' : value}
            paddingLeft={0}
            containerMargin="5px"
            handleUpdate={(event): void => {
                handleUpdate(event.target.value as ConditionTypes);
            }}
            {...others}
        >
            {types
                .filter(
                    (type) =>
                        (type !== ConditionTypes.ENTITYVALUE &&
                            type !== ConditionTypes.COMPANY &&
                            type !== ConditionTypes.FLOW &&
                            type !== ConditionTypes.AI &&
                            type !== ConditionTypes.EXPRESSION &&
                            type !== ConditionTypes.CONVERSATION_STEP) ||
                        (type === ConditionTypes.CONVERSATION_STEP &&
                            context.conversation &&
                            previousSteps.length > 0) ||
                        (type === ConditionTypes.COMPANY &&
                            organization?.idCompanyEntity)
                )
                .map((type) => (
                    <MenuItem
                        key={type}
                        value={type}
                        style={{ paddingLeft: 0 }}
                    >
                        <ConditionTypeComponent type={type} />
                    </MenuItem>
                ))}
            {types.includes(ConditionTypes.ENTITYVALUE) &&
                !context.entityValue?.idEntity &&
                project?.entities
                    .filter(
                        (entity) =>
                            entity.idEntity !== organization?.idCompanyEntity &&
                            entities[entity.idEntity].active
                    )
                    .map((entity) => (
                        <MenuItem
                            key={entity.idEntity}
                            value={entity.idEntity}
                            style={{ paddingLeft: 0 }}
                        >
                            <EntityPropertyComponent entity={entity} />
                        </MenuItem>
                    ))}
            {types.includes(ConditionTypes.ENTITYVALUE) &&
                context.entityValue?.idEntity && (
                    <MenuItem
                        key={context.entityValue?.idEntity}
                        value={context.entityValue?.idEntity}
                        style={{ paddingLeft: 0 }}
                    >
                        <EntityPropertyComponent
                            entity={{
                                idEntity: context.entityValue.idEntity,
                                multiple: false,
                            }}
                        />
                    </MenuItem>
                )}
            {types.includes(ConditionTypes.FLOW) && (
                <MenuItem
                    key={ConditionTypes.FLOW}
                    value={ConditionTypes.FLOW}
                    style={{ paddingLeft: 0 }}
                >
                    <ConditionTypeComponent type={ConditionTypes.FLOW} />
                </MenuItem>
            )}
            {types.includes(
                ConditionTypes.AI || value === ConditionTypes.AI
            ) && (
                <MenuItem
                    key={ConditionTypes.AI}
                    value={ConditionTypes.AI}
                    style={{ paddingLeft: 0 }}
                >
                    <ConditionTypeComponent type={ConditionTypes.AI} />
                </MenuItem>
            )}
            <MenuItem
                key={ConditionTypes.EXPRESSION}
                value={ConditionTypes.EXPRESSION}
                style={{ paddingLeft: 0 }}
            >
                <ConditionTypeComponent type={ConditionTypes.EXPRESSION} />
            </MenuItem>
        </RoundedSelect>
    );
}
export default RoundedConditionPicker;

function ConditionTypeComponent({
    type,
}: {
    type: ConditionTypes | undefined;
}): JSX.Element {
    switch (type) {
        case ConditionTypes.SUBJECT: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.subjectLbl}>
                            <TitleIcon fontSize="inherit" />
                        </div>
                    </div>
                    Asunto
                </div>
            );
        }
        case ConditionTypes.TEXT: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.textAreaContainer}>
                            <SubjectIcon fontSize="inherit" />
                        </div>
                    </div>
                    Mensaje
                </div>
            );
        }
        case ConditionTypes.CLASSIFIER: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <img
                            className={styles.shapesIcon}
                            alt={'shapes'}
                            src={'/media/icons/shapes.svg'}
                        />
                    </div>
                    Clasificador
                </div>
            );
        }
        case ConditionTypes.CLIENT: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.clientContainer}>
                            <PersonRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    Cliente
                </div>
            );
        }
        case ConditionTypes.SENTIMENT: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.sentimentContainer}>
                            <SentimentVerySatisfiedIcon fontSize="inherit" />
                        </div>
                    </div>
                    Tono
                </div>
            );
        }
        case ConditionTypes.COMPANY: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <img
                            className={styles.companyIcon}
                            src="/media/icons/bluehandshake.svg"
                        />
                    </div>
                    Empresa
                </div>
            );
        }
        case ConditionTypes.SURVEY: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <img
                            className={styles.stateIcon}
                            src="/media/icons/bluesurvey.svg"
                        />
                    </div>
                    Encuesta
                </div>
            );
        }
        case ConditionTypes.ENTRY: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <EntryIcon
                            fill="var(--secondary)"
                            style={{
                                fontSize: 30,
                            }}
                        />
                    </div>
                    Entrada
                </div>
            );
        }
        case ConditionTypes.FLOW: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.flowsIcon}>
                            <FlowIcon
                                style={{ height: 24, width: 24 }}
                                fill={'var(--secondary)'}
                            />
                        </div>
                    </div>
                    Flujos
                </div>
            );
        }
        case ConditionTypes.AI: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <BrainIcon
                            style={{ height: 34, width: 34 }}
                            fill={'var(--secondary)'}
                        />
                    </div>
                    Inteligencia Artificial
                </div>
            );
        }
        case ConditionTypes.EXPRESSION: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.expressionIcon}>
                            <CallSplitRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    Subcondición
                </div>
            );
        }
        case ConditionTypes.FORM_STEP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.formStepIcon}>A..</div>
                    </div>
                    Paso Anterior
                </div>
            );
        }
        case ConditionTypes.CONVERSATION_STEP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <WhatsappIcon
                            fill="var(--secondary)"
                            style={{
                                height: 28,
                                width: 30,
                            }}
                        />
                    </div>
                    Paso Anterior
                </div>
            );
        }
        default:
            return <div></div>;
    }
}
function EntityPropertyComponent({
    entity: { idEntity, multiple },
}: {
    entity: Pick<ProjectEntity, 'idEntity' | 'multiple'>;
}): JSX.Element {
    const entity = useSelector(
        (state: RootState) => state.site.entities[idEntity]
    );
    return (
        <div className={styles.property}>
            <div className={styles.icon}>
                <EntityIcon idEntity={idEntity} fill={'var(--secondary)'} />
            </div>
            {multiple ? entity.pluralName : entity.name}
        </div>
    );
}
