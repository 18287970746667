import { MenuItem } from '@material-ui/core';
import React from 'react';
import EntityPropertyOperators from '../../../../../constants/Conditions/EntityPropertyOperators';
import EntityProperties from '../../../../../constants/Entities/EntityPropertyTypes';
import RoundedSelect from '../../../../RoundedSelect/RoundedSelect';
import styles from '../../Condition.module.css';
import entityStyles from '../EntityCondition.module.css';
import {
    EntityAgentCondition,
    EntityApiCondition,
    EntityCheckboxCondition,
    EntityDateCondition,
    EntityPropertyCondition,
    EntityRelativeDateCondition,
    EntitySelectorCondition,
    EntityTextAreaCondition,
    EntityTextInputCondition,
    EntityTimeCondition,
} from '../../../../../@Types/ConditionTypes/EntityCondition';
import { Entity } from '../../../../../@Types/@Types';
import RoundedMultiSelect from '../../../../RoundedMultiSelect/RoundedMultiSelect';
import RoundedCheckBox from '../../../../RoundedCheckBox/RoundedCheckBox';
import RoundedDatePicker from '../../../../@Pickers/RoundedDatePicker/RoundedDatePicker';
import RoundedAgentPicker from '../../../../@Pickers/RoundedAgentPicker/RoundedAgentPicker';
import RoundedTextField from '../../../../RoundedTextField/RoundedTextField';
import { ConditionProps } from '../../../Condition';
import { EntityApiTypes } from '../../../../../constants/Entities/EntityApiTypes';
import produce from 'immer';
import Toggle from '../../../../Toggle/Toggle';
import EntityPropertyTypes from '../../../../../constants/Entities/EntityPropertyTypes';
import { isPhoneStep } from '../../../../../utils/PhoneFunctions';
import RoundedPhoneInput from '../../../../RoundedPhoneInput/RoundedPhoneInput';
interface EntityPropertyConditionProps
    extends ConditionProps<EntityPropertyCondition<any>> {
    /** The entity to decide on */
    entity: Entity;
}
function EntityPropertyConditionComponent({
    entity,
    context,
    condition,
    hoverDelete,
    handleUpdate,
}: EntityPropertyConditionProps): JSX.Element {
    const entityProperty = entity.steps[condition.idProperty ?? ''];
    const renderPropertyOperator = (): JSX.Element => {
        switch (entityProperty?.type) {
            case EntityProperties.SELECTOR:
            case EntityProperties.TEXTINPUT:
            case EntityProperties.CHECKBOX:
            case EntityProperties.AGENTPICKER: {
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={condition.propertyOperator ?? ''}
                            label="Operador"
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    propertyOperator: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={EntityPropertyOperators.EQUAL}>
                                Igual a
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEQUAL}>
                                No igual a
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.EXISTS}>
                                Existente
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEXISTS}>
                                No existente
                            </MenuItem>
                        </RoundedSelect>
                    </div>
                );
            }
            case EntityProperties.TEXTAREA: {
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={condition.propertyOperator ?? ''}
                            label="Operador"
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    propertyOperator: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={EntityPropertyOperators.EQUAL}>
                                Contiene
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEQUAL}>
                                No contiene
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.EXISTS}>
                                Existente
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEXISTS}>
                                No existente
                            </MenuItem>
                        </RoundedSelect>
                    </div>
                );
            }
            case EntityProperties.DATEPICKER: {
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={condition.propertyOperator ?? ''}
                            label="Operador"
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    propertyOperator: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={EntityPropertyOperators.EQUAL}>
                                Igual a
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEQUAL}>
                                No igual a
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.LESS}>
                                Antes de
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.MORE}>
                                Después de
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.EXISTS}>
                                Existente
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEXISTS}>
                                No existente
                            </MenuItem>
                            <MenuItem
                                value={
                                    EntityPropertyOperators.PAST_RELATIVE_MORE
                                }
                            >
                                Hace menos de
                            </MenuItem>
                            <MenuItem
                                value={
                                    EntityPropertyOperators.PAST_RELATIVE_LESS
                                }
                            >
                                Hace más de
                            </MenuItem>
                            <MenuItem
                                value={
                                    EntityPropertyOperators.FUTURE_RELATIVE_LESS
                                }
                            >
                                Es en menos de
                            </MenuItem>
                            <MenuItem
                                value={
                                    EntityPropertyOperators.FUTURE_RELATIVE_MORE
                                }
                            >
                                Es en más de
                            </MenuItem>
                        </RoundedSelect>
                    </div>
                );
            }
            case EntityProperties.TIMEPICKER: {
                if (entityProperty.type !== EntityProperties.TIMEPICKER)
                    return <></>;
                return (
                    <React.Fragment>
                        <div className={styles.selectorContainer}>
                            <RoundedSelect
                                value={condition.propertyOperator}
                                label="Operador"
                                containerMargin="0px"
                                error={hoverDelete}
                                handleUpdate={(event): void => {
                                    handleUpdate({
                                        ...condition,
                                        propertyOperator: event.target.value,
                                    });
                                }}
                            >
                                {(entityProperty.working === undefined ||
                                    entityProperty.pickDays ||
                                    entityProperty.pickHours ||
                                    entityProperty.pickMinutes) && (
                                    <MenuItem
                                        value={EntityPropertyOperators.INCLUDES}
                                    >
                                        Contiene
                                    </MenuItem>
                                )}
                                {(entityProperty.working === undefined ||
                                    entityProperty.pickDays ||
                                    entityProperty.pickHours ||
                                    entityProperty.pickMinutes) && (
                                    <MenuItem
                                        value={
                                            EntityPropertyOperators.NOTINCLUDES
                                        }
                                    >
                                        No Contiene
                                    </MenuItem>
                                )}
                                <MenuItem
                                    value={EntityPropertyOperators.EXISTS}
                                >
                                    Existente
                                </MenuItem>
                                <MenuItem
                                    value={EntityPropertyOperators.NOTEXISTS}
                                >
                                    No existente
                                </MenuItem>
                            </RoundedSelect>
                        </div>
                        {isTimeCondition(condition) && (
                            <div className={styles.selectorContainer}>
                                <RoundedSelect
                                    value={condition.property}
                                    label="Propiedad"
                                    containerMargin="0px"
                                    error={hoverDelete}
                                    handleUpdate={(event): void => {
                                        handleUpdate({
                                            ...condition,
                                            property: event.target.value,
                                        });
                                    }}
                                >
                                    {entityProperty.pickDays && (
                                        <MenuItem value={'days'}>Días</MenuItem>
                                    )}
                                    {entityProperty.pickHours && (
                                        <MenuItem value={'hours'}>
                                            Horas
                                        </MenuItem>
                                    )}
                                    {entityProperty.pickMinutes && (
                                        <MenuItem value={'minutes'}>
                                            Minutos
                                        </MenuItem>
                                    )}
                                    {entityProperty.working === undefined && (
                                        <MenuItem value={'working'}>
                                            Tipo de Tiempo
                                        </MenuItem>
                                    )}
                                </RoundedSelect>
                            </div>
                        )}
                        {isTimeCondition(condition) && condition.property && (
                            <div className={styles.selectorContainer}>
                                <RoundedSelect
                                    value={condition.timePropertyOperator}
                                    label="Operador"
                                    containerMargin="0px"
                                    error={hoverDelete}
                                    handleUpdate={(event): void => {
                                        handleUpdate({
                                            ...condition,
                                            timePropertyOperator:
                                                event.target.value,
                                        });
                                    }}
                                >
                                    <MenuItem
                                        value={EntityPropertyOperators.EQUAL}
                                    >
                                        Igual a
                                    </MenuItem>
                                    <MenuItem
                                        value={EntityPropertyOperators.NOTEQUAL}
                                    >
                                        No igual a
                                    </MenuItem>
                                    {condition.property !== 'working' && (
                                        <MenuItem
                                            value={EntityPropertyOperators.LESS}
                                        >
                                            Menor que
                                        </MenuItem>
                                    )}
                                    {condition.property !== 'working' && (
                                        <MenuItem
                                            value={EntityPropertyOperators.MORE}
                                        >
                                            Mayor que
                                        </MenuItem>
                                    )}
                                </RoundedSelect>
                            </div>
                        )}
                    </React.Fragment>
                );
            }
            default:
                if (!entityProperty) {
                    const api = entity.apis?.find(
                        (api) => api.id === condition.idProperty
                    );
                    if (!api) return <div></div>;
                    if (api.type === EntityApiTypes.CONDITION) {
                        return (
                            <div className={styles.selectorContainer}>
                                <RoundedSelect
                                    value={condition.propertyOperator ?? ''}
                                    label="Operador"
                                    containerMargin="0px"
                                    error={hoverDelete}
                                    handleUpdate={(event): void => {
                                        handleUpdate({
                                            ...condition,
                                            propertyOperator:
                                                event.target.value,
                                        });
                                    }}
                                >
                                    {api.operators.includes(
                                        EntityPropertyOperators.EQUAL
                                    ) && (
                                        <MenuItem
                                            value={
                                                EntityPropertyOperators.EQUAL
                                            }
                                        >
                                            Igual a
                                        </MenuItem>
                                    )}
                                    {api.operators.includes(
                                        EntityPropertyOperators.EQUAL
                                    ) && (
                                        <MenuItem
                                            value={
                                                EntityPropertyOperators.NOTEQUAL
                                            }
                                        >
                                            No igual a
                                        </MenuItem>
                                    )}
                                    {api.operators.includes(
                                        EntityPropertyOperators.EQUAL
                                    ) && (
                                        <MenuItem
                                            value={EntityPropertyOperators.LESS}
                                        >
                                            Menor que
                                        </MenuItem>
                                    )}
                                    {api.operators.includes(
                                        EntityPropertyOperators.EQUAL
                                    ) && (
                                        <MenuItem
                                            value={EntityPropertyOperators.MORE}
                                        >
                                            Mayor que
                                        </MenuItem>
                                    )}
                                </RoundedSelect>
                            </div>
                        );
                    }
                }
                return <div></div>;
        }
    };

    const renderPropertyValue = (): JSX.Element => {
        if (
            !condition.propertyOperator ||
            condition.propertyOperator === EntityPropertyOperators.EXISTS ||
            condition.propertyOperator === EntityPropertyOperators.NOTEXISTS
        ) {
            return <div></div>;
        }
        switch (entityProperty?.type) {
            case EntityProperties.SELECTOR: {
                const typedCondition =
                    condition as EntitySelectorCondition<any>;
                return (
                    <div className={entityStyles.valueContainer}>
                        <div className={styles.selectorContainer}>
                            <RoundedSelect
                                value={typedCondition.propertyValue ?? ''}
                                label={entityProperty.label}
                                containerMargin="0px"
                                error={hoverDelete}
                                handleUpdate={(event): void => {
                                    handleUpdate({
                                        ...condition,
                                        propertyValue: event.target.value,
                                    });
                                }}
                            >
                                {entityProperty.options.map((option) => (
                                    <MenuItem
                                        value={option.value}
                                        key={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </RoundedSelect>
                        </div>
                    </div>
                );
            }
            case EntityProperties.TEXTINPUT: {
                const typedCondition =
                    condition as EntityTextInputCondition<any>;
                if (isPhoneStep(entityProperty)) {
                    return (
                        <div className={entityStyles.valueContainer}>
                            <div className={styles.selectorContainer}>
                                <RoundedPhoneInput
                                    label={entityProperty.label}
                                    value={typedCondition.propertyValue ?? ''}
                                    error={hoverDelete}
                                    height="40px"
                                    onChange={(propertyValue): void => {
                                        handleUpdate({
                                            ...condition,
                                            propertyValue: propertyValue as any,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                }
                return (
                    <div className={entityStyles.valueContainer}>
                        <div className={styles.selectorContainer}>
                            <RoundedTextField
                                label={entityProperty.label}
                                value={typedCondition.propertyValue ?? ''}
                                height="40px"
                                error={hoverDelete}
                                onChange={(event): void => {
                                    handleUpdate({
                                        ...condition,
                                        propertyValue: event.target
                                            .value as any,
                                    });
                                }}
                            />
                        </div>
                    </div>
                );
            }
            case EntityProperties.TEXTAREA: {
                const typedCondition =
                    condition as EntityTextAreaCondition<any>;
                return (
                    <div className={entityStyles.valueContainer}>
                        <div className={styles.multiSelectContainer}>
                            <RoundedMultiSelect
                                values={typedCondition.propertyValues ?? []}
                                options={[]} //TODO: generar sugestiones.
                                maxTags={5}
                                label="Valores"
                                fullWidth
                                error={hoverDelete}
                                handleUpdate={(values: string[]): void => {
                                    handleUpdate({
                                        ...typedCondition,
                                        propertyValues: values,
                                    });
                                }}
                            />
                        </div>
                    </div>
                );
            }
            case EntityProperties.CHECKBOX: {
                const typedCondition =
                    condition as EntityCheckboxCondition<any>;
                return (
                    <div className={entityStyles.valueContainer}>
                        <div className={styles.checkboxContainer}>
                            <RoundedCheckBox
                                padding="0px"
                                size="2rem"
                                error={hoverDelete}
                                onChange={(event): void => {
                                    handleUpdate({
                                        ...condition,
                                        propertyValue: event.target
                                            .checked as any,
                                    });
                                }}
                                checked={Boolean(typedCondition.propertyValue)}
                            />
                        </div>
                    </div>
                );
            }
            case EntityProperties.DATEPICKER: {
                if (
                    condition.propertyOperator ===
                        EntityPropertyOperators.EQUAL ||
                    condition.propertyOperator ===
                        EntityPropertyOperators.NOTEQUAL ||
                    condition.propertyOperator ===
                        EntityPropertyOperators.LESS ||
                    condition.propertyOperator === EntityPropertyOperators.MORE
                ) {
                    const typedCondition =
                        condition as EntityDateCondition<any>;
                    return (
                        <div className={entityStyles.valueContainer}>
                            <div className={styles.selectorContainer}>
                                <RoundedDatePicker
                                    label={entityProperty.label}
                                    value={typedCondition.propertyValue ?? null}
                                    error={hoverDelete}
                                    onChange={(date): void => {
                                        if (date) {
                                            handleUpdate({
                                                ...condition,
                                                propertyValue: date as any,
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    );
                } else if (
                    condition.propertyOperator ===
                        EntityPropertyOperators.PAST_RELATIVE_LESS ||
                    condition.propertyOperator ===
                        EntityPropertyOperators.PAST_RELATIVE_MORE ||
                    condition.propertyOperator ===
                        EntityPropertyOperators.FUTURE_RELATIVE_LESS ||
                    condition.propertyOperator ===
                        EntityPropertyOperators.FUTURE_RELATIVE_MORE
                ) {
                    const typedCondition =
                        condition as EntityRelativeDateCondition<any>;
                    return (
                        <div className={styles.timeContainer}>
                            <div className={styles.dayContainer}>
                                <RoundedTextField
                                    label="Días"
                                    height="40px"
                                    onChange={(event): void =>
                                        handleUpdate(
                                            produce(
                                                typedCondition,
                                                (condition) => {
                                                    if (!event.target.value)
                                                        return;
                                                    if (!condition.value)
                                                        condition.value = {};
                                                    condition.value.days =
                                                        parseInt(
                                                            event.target.value
                                                        );
                                                }
                                            )
                                        )
                                    }
                                    type="number"
                                    value={typedCondition.value?.days ?? 0}
                                />
                            </div>
                            <div className={styles.dayContainer}>
                                <RoundedTextField
                                    label="Horas"
                                    height="40px"
                                    onChange={(event): void =>
                                        handleUpdate(
                                            produce(
                                                typedCondition,
                                                (condition) => {
                                                    if (!event.target.value)
                                                        return;
                                                    if (!condition.value)
                                                        condition.value = {};
                                                    condition.value.hours =
                                                        parseInt(
                                                            event.target.value
                                                        );
                                                }
                                            )
                                        )
                                    }
                                    type="number"
                                    value={typedCondition.value?.hours ?? 0}
                                />
                            </div>
                            <div className={styles.dayContainer}>
                                <RoundedTextField
                                    label="Minutos"
                                    height="40px"
                                    onChange={(event): void =>
                                        handleUpdate(
                                            produce(
                                                typedCondition,
                                                (condition) => {
                                                    if (!event.target.value)
                                                        return;
                                                    if (!condition.value)
                                                        condition.value = {};
                                                    condition.value.minutes =
                                                        parseInt(
                                                            event.target.value
                                                        );
                                                }
                                            )
                                        )
                                    }
                                    type="number"
                                    value={typedCondition.value?.minutes ?? 0}
                                />
                            </div>
                            {context.form === undefined && (
                                <div className={styles.toggleLabel}>
                                    Hábiles:
                                    <Toggle
                                        size="small"
                                        checked={typedCondition.value.working}
                                        onChange={(working: boolean): void => {
                                            handleUpdate(
                                                produce(
                                                    typedCondition,
                                                    (condition) => {
                                                        if (!condition.value)
                                                            condition.value =
                                                                {};
                                                        condition.value.working =
                                                            working;
                                                    }
                                                )
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    );
                }
                return <></>;
            }
            case EntityProperties.TIMEPICKER: {
                const typedCondition = condition as EntityTimeCondition<any>;
                if (!typedCondition.property) return <></>;
                if (typedCondition.property === 'working') {
                    return (
                        <div className={styles.checkboxContainer}>
                            <RoundedCheckBox
                                padding="0px"
                                size="2rem"
                                error={hoverDelete}
                                onChange={(event): void => {
                                    handleUpdate({
                                        ...typedCondition,
                                        value: !!event.target.checked,
                                    } as any);
                                }}
                                checked={Boolean(typedCondition.value)}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div className={styles.selectorContainer}>
                            <RoundedTextField
                                value={typedCondition.value}
                                label={calcTimeConditionLabel(typedCondition)}
                                inputProps={{ min: 0 }}
                                onChange={(event): void => {
                                    const copy = { ...typedCondition };
                                    copy.value = parseInt(event.target.value);
                                    handleUpdate(copy);
                                }}
                                type="number"
                            ></RoundedTextField>
                        </div>
                    );
                }
            }
            case EntityProperties.AGENTPICKER: {
                const typedCondition = condition as EntityAgentCondition<any>;
                return (
                    <div className={entityStyles.valueContainer}>
                        <div className={styles.multiSelectContainer}>
                            <RoundedAgentPicker
                                value={typedCondition.propertyValues ?? []}
                                label={entityProperty.label}
                                multiple={true}
                                height="40px"
                                error={hoverDelete}
                                handleUpdate={(agents): void => {
                                    handleUpdate({
                                        ...typedCondition,
                                        propertyValues: agents as any,
                                    });
                                }}
                            />
                        </div>
                    </div>
                );
            }
            default:
                if (!entityProperty && isApiCondition(condition)) {
                    const api = entity.apis?.find(
                        (api) => api.id === condition.idProperty
                    );
                    if (api?.type !== EntityApiTypes.CONDITION)
                        return <div></div>;

                    switch (api.valueType) {
                        case 'string': {
                            return (
                                <div className={entityStyles.valueContainer}>
                                    <div className={styles.selectorContainer}>
                                        <RoundedTextField
                                            label={api.valueType}
                                            value={
                                                condition.propertyValue ?? ''
                                            }
                                            height="40px"
                                            error={hoverDelete}
                                            onChange={(event): void => {
                                                handleUpdate({
                                                    ...condition,
                                                    propertyValue: event.target
                                                        .value as any,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        case 'string-list': {
                            return (
                                <div className={styles.valueContainer}>
                                    <div
                                        className={styles.multiSelectContainer}
                                    >
                                        <RoundedMultiSelect
                                            values={
                                                condition.propertyValue ?? []
                                            }
                                            label={api.valueLabel}
                                            fullWidth
                                            error={hoverDelete}
                                            handleUpdate={(
                                                values: string[]
                                            ): void => {
                                                handleUpdate({
                                                    ...condition,
                                                    propertyValue: values,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        default:
                            return <div></div>;
                    }
                }
                return <div></div>;
        }
    };
    return (
        <React.Fragment>
            <div className={styles.operatorContainer}>
                {renderPropertyOperator()}
            </div>
            {renderPropertyValue()}
        </React.Fragment>
    );
}
export default EntityPropertyConditionComponent;

function isApiCondition(
    condition: EntityPropertyCondition<any>
): condition is EntityApiCondition<any> {
    return condition.propertyType === EntityProperties.API;
}

const isTimeCondition = (
    condition: EntityPropertyCondition<any>
): condition is EntityTimeCondition<any> => {
    return (
        condition.propertyType === EntityPropertyTypes.TIMEPICKER &&
        (condition.propertyOperator === EntityPropertyOperators.INCLUDES ||
            condition.propertyOperator === EntityPropertyOperators.NOTINCLUDES)
    );
};

function calcTimeConditionLabel(
    typedCondition: EntityTimeCondition<any>
): string {
    switch (typedCondition.property) {
        case 'days':
            return 'Días';
        case 'hours':
            return 'Horas';
        case 'minutes':
            return 'Minutos';
        case 'working':
            return 'Tipo de Tiempo';
        default:
            return '';
    }
}
