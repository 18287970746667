import styles from './EntityValueStep.module.css';
import React, { useContext, useRef } from 'react';
import { EntityValueStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch, useSelector } from 'react-redux';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import EntityPropertyTypes from '../../../../../../constants/Entities/EntityPropertyTypes';
import { RootState } from '../../../../../../utils/_store';
// import Toggle from '../../../../../../shared/Toggle/Toggle';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import {
    EntityValueFilterTypes,
    EntityValuePathTypes,
} from '../../../../../../constants/FormStepTypes';
import EntityValuePath from '../../../../../../shared/@Steps/EntityValueStep/EntityValuePath/EntityValuePath';
import EntityValueFilter from '../../../../../../shared/@Steps/EntityValueStep/EntityValueFilter/EntityValueFilter';
import { EntityIntegrationTypes } from '../../../../../../constants/Entities/EntityIntegrationTypes';
import RoundedConversationStepPicker from '../../../../../../shared/@Pickers/RoundedConversationStepPicker/RoundedConversationStepPicker';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';

interface EntityValueStepProps {
    step: EntityValueStep;
}
const IntegrationFilterTypes = [EntityIntegrationTypes.CBR_ID_INMUEBLE];

function EntityValueStepComponent({ step }: EntityValueStepProps): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);
    const addBtnRef = useRef<any>();

    const siteInfo = useSelector((state: RootState) => state.site);

    const entity = siteInfo.entities[step.idEntity];

    const properties = Object.values(entity.steps).filter(
        (property) =>
            property.type === EntityPropertyTypes.ENTITY_RELATIONSHIP ||
            IntegrationFilterTypes.find((integration) =>
                entity.integrations[integration]?.steps.includes(property.id)
            )
    );

    const newProperties = properties.filter(
        (property) =>
            !step.filters.find((filter) => filter.idProperty === property.id)
    );

    const previousSteps = useSelector(
        (state: RootState) =>
            state.conversationEditor.stepDependencies[step.id ?? '']
                .previousSteps
    );
    return (
        <React.Fragment>
            <div className={styles.headerContainer}>
                <RoundedTextField
                    label="Encabezado"
                    multiline
                    maxLength={60}
                    value={step.header}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, header: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.container}>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        placeholder={'Mensaje'}
                        type={TextEditorTypes.WHATSAPP}
                        idEditor={`Conversation-${apiKey}-${step.id}`}
                        payload={{ draft: step.message }}
                        onChange={({ draft: message }): void => {
                            if (!message) return;
                            dispatch(updateStep({ ...step, message }));
                        }}
                        media={false}
                        context={{ client: true }}
                        conditions={{
                            types: BaseConversationPayloadConditionTypes,
                            context: {
                                conversation: {
                                    idStep: step.id,
                                },
                            },
                        }}
                        minHeight={144}
                        maxHeight={400}
                        required
                        maxLength={4096}
                    />
                </div>
            </div>
            <div className={styles.footerContainer}>
                <RoundedTextField
                    label="Pie"
                    multiline
                    maxLength={60}
                    value={step.footer}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, footer: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.btnLblContainer}>
                <RoundedTextField
                    label="Botón"
                    value={step.label}
                    maxLength={20}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({
                                ...step,
                                label: e.target.value,
                            })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.pathContainer}>
                {step.path.map((path, index) => {
                    if (
                        path.type === EntityValuePathTypes.VALUE &&
                        path.idEntityValue === null
                    )
                        return <div key={path.idEntity + '' + index}></div>;
                    return (
                        <EntityValuePath
                            path={path}
                            key={path.idEntity + '' + index}
                            handleUpdate={(path): void => {
                                const newPath = [...step.path];
                                newPath[index] = path;
                                dispatch(
                                    updateStep({
                                        ...step,
                                        path: newPath,
                                    })
                                );
                            }}
                        />
                    );
                })}
            </div>
            {/* <div className={styles.toggleLabelContainer}>
                Mostrar en la Traza:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.showInTrace}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({ ...step, showInTrace: checked })
                            );
                        }}
                    />
                </div>
            </div> */}
            {properties.length > 0 && (
                <React.Fragment>
                    <div className={styles.filtersLabel}>Filtros:</div>
                    <div className={styles.filtersContainer}>
                        {step.filters.map((filter, index) => (
                            <EntityValueFilter
                                filter={filter}
                                properties={[
                                    filter.idProperty,
                                    ...newProperties.map((prop) => prop.id),
                                ]}
                                integrations={IntegrationFilterTypes}
                                key={index}
                                entity={entity}
                                showRequired={false}
                                handleUpdate={(filter): void => {
                                    const newFilters = [...step.filters];
                                    newFilters[index] = filter;
                                    dispatch(
                                        updateStep({
                                            ...step,
                                            filters: newFilters,
                                        })
                                    );
                                }}
                                handleDelete={(): void => {
                                    const newFilters = [...step.filters];
                                    newFilters.splice(index, 1);
                                    dispatch(
                                        updateStep({
                                            ...step,
                                            filters: newFilters,
                                        })
                                    );
                                }}
                                renderStepPicker={(
                                    idStep,
                                    handleUpdate
                                ): JSX.Element => (
                                    <RoundedConversationStepPicker
                                        containerMargin="0px"
                                        stepOrder={previousSteps}
                                        label="Paso del identificador"
                                        value={idStep}
                                        handleUpdate={(step): void =>
                                            handleUpdate(step.id)
                                        }
                                    />
                                )}
                            />
                        ))}
                        {newProperties.length > 0 && (
                            <div
                                className={styles.addOption}
                                onClick={(): void => {
                                    const idProperty = newProperties[0].id;
                                    if (
                                        entity.steps?.[idProperty ?? '']
                                            ?.type ===
                                        EntityPropertyTypes.ENTITY_RELATIONSHIP
                                    ) {
                                        dispatch(
                                            updateStep({
                                                ...step,
                                                filters: [
                                                    ...step.filters,
                                                    {
                                                        idProperty,
                                                        type: EntityValueFilterTypes.STEP,
                                                        any: true,
                                                        required: false,
                                                    },
                                                ],
                                            })
                                        );
                                    } else {
                                        const integration =
                                            IntegrationFilterTypes.find(
                                                (integration) =>
                                                    entity.integrations[
                                                        integration
                                                    ]?.steps.includes(
                                                        idProperty
                                                    )
                                            );
                                        if (integration) {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    filters: [
                                                        ...step.filters,
                                                        {
                                                            idProperty,
                                                            type: EntityValueFilterTypes.INTEGRATION,
                                                            integration,
                                                        } as any,
                                                    ],
                                                })
                                            );
                                        } else {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    filters: [
                                                        ...step.filters,
                                                        {
                                                            idProperty,
                                                            type: EntityValueFilterTypes.VALUE,
                                                            value: [],
                                                        },
                                                    ],
                                                })
                                            );
                                        }
                                    }
                                }}
                                ref={addBtnRef}
                            >
                                <div className={styles.addIconContainer}>
                                    <AddRoundedIcon fontSize="inherit" />
                                </div>
                                <div
                                    className={
                                        styles.addOptionLbL + ' noselect'
                                    }
                                >
                                    Agregar Filtro
                                </div>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default EntityValueStepComponent;
