/**
 * Operators available to all conditions
 */
enum ConditionOperators {
    EQUAL = 'EQUAL',
    NOTEQUAL = 'NOTEQUAL',
    INCLUDES = 'INCLUDES',
    NOTINCLUDES = 'NOTINCLUDES',
    LESS = 'LESS',
    MORE = 'MORE',
    EXISTS = 'EXISTS',
    NOTEXISTS = 'NOTEXISTS',
}

export const ConditionEqualsOperators = [
    ConditionOperators.EQUAL,
    ConditionOperators.NOTEQUAL,
];

export const ConditionExistsOperators = [
    ConditionOperators.EXISTS,
    ConditionOperators.NOTEXISTS,
];

export const ConditionIncludesOperators = [
    ConditionOperators.INCLUDES,
    ConditionOperators.NOTINCLUDES,
];

export default ConditionOperators;
