import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { fetchVisualizationsTabs } from '../../../controllers/DashboardController/DashboardService';
import { Visualization } from '../../../@Types/Metabase';

function a11yProps(index: any): Record<string, any> {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        backgroundColor: 'transparent',
        color: 'var(--secondary)',
        boxShadow: 'none',
        '& .Erk-MuiTabs-indicator': {
            backgroundColor: 'var(--secondary)',
        },
        marginTop: 'auto',
        marginBottom: 'auto',
    },
}));
interface TabsProps {
    /** Function called when the agent has been created */
    setVisualization: Function;
}
export default function ScrollableTabsButtonAuto({
    setVisualization,
}: TabsProps): JSX.Element {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = React.useState(0);
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const genericDashboard: Visualization = {
        title: 'Control de Casos',
        type: 'GenericDashboard',
        id: null,
        public: false,
        description: null,
    };
    const [options, setOptions] = React.useState<Visualization[]>([]);

    async function getVisualizationsAsync(): Promise<void> {
        try {
            if (idProject) {
                const visualizations = await fetchVisualizationsTabs(idProject);
                visualizations.push(genericDashboard);
                visualizations.sort((a) => {
                    if (a.title.toLowerCase().includes('tablero')) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                setOptions(visualizations);
                setCurrentTab(0);
                setVisualization(visualizations[0]);
            }
        } catch (error) {
            console.error(error);
            setOptions([genericDashboard]);
            setVisualization(genericDashboard);
        }
    }

    useEffect(() => {
        setVisualization(undefined);
        setOptions([]);
        setCurrentTab(0);
        getVisualizationsAsync();
    }, [idProject]);

    return (
        <AppBar position="static" classes={classes}>
            <Tabs
                value={currentTab}
                onChange={(
                    event: React.ChangeEvent<{}>,
                    newTab: number
                ): void => {
                    setCurrentTab(newTab);
                    const visualization = options[newTab];
                    if (visualization) {
                        setVisualization(visualization);
                    }
                }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                {options.map((option, index) => (
                    <Tab
                        label={option.title}
                        {...a11yProps(index)}
                        key={index}
                    />
                ))}
            </Tabs>
        </AppBar>
    );
}
