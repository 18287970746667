export enum ConditionTypes {
    EXPRESSION = 'EXPRESSION',
    CLASSIFIER = 'CLASSIFIER',
    COMPANY = 'COMPANY',
    ENTITYVALUE = 'ENTITYVALUE',
    FORM_STEP = 'FORM_STEP',
    CONVERSATION_STEP = 'CONVERSATION_STEP',
    TICKET_VALUE = 'TICKET_VALUE',
    CONCEPT = 'CONCEPT',
    RELATIONSHIP = 'RELATIONSHIP',
    ENTRY = 'ENTRY',
    CLIENT = 'CLIENT',
    SENTIMENT = 'SENTIMENT',
    TEXT = 'TEXT',
    CUSTOM = 'CUSTOM',
    SUBJECT = 'SUBJECT',
    SURVEY = 'SURVEY',
    FLOW = 'FLOW',
    AI = 'AI',
}
export default ConditionTypes;
