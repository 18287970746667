import React, { useState } from 'react';
import { fetchVisualization } from '../../controllers/DashboardController/DashboardService';
import styles from './Analytics.module.css';
import Tabs from './Tabs/Tabs';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
import { Visualization } from '../../@Types/Metabase';
import GenericDashboard from './GenericDashboard/GenericDashboard';
import FrameWithFilters from './FrameWithFilters/FrameWithFilters';
import Loader from '../../shared/Loader/Loader';
interface AnalyticsProps {
    mobile: boolean;
}
function Analytics({ mobile }: AnalyticsProps): JSX.Element {
    const [urlLink, setUrlLink] = useState('');
    const [visualization, setVisualization] = useState<
        Visualization | undefined
    >(undefined);
    const siteInfo = useSelector((state: RootState) => state.site);
    const [loading, setLoading] = useState(true);
    const [iframeLoaded, setIframeLoaded] = useState(false);

    async function getUrlAsync(visualization: Visualization): Promise<void> {
        try {
            if (visualization?.id === null) {
                setLoading(false);
            } else if (visualization && siteInfo.idProject) {
                const url = await fetchVisualization(
                    visualization,
                    siteInfo.idProject
                );
                const splittedUrl = url.split('#');
                setUrlLink(splittedUrl[0] + '#bordered=false&titled=false');
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleVisulizationChange = (
        visualization: Visualization | undefined
    ): void => {
        setIframeLoaded(false);
        setLoading(true);
        setVisualization(visualization);
        if (visualization) {
            getUrlAsync(visualization);
        }
    };

    const renderIframe = (): any => {
        if (loading) {
            return <div></div>;
        } else if (visualization?.description?.includes('filters')) {
            return (
                <FrameWithFilters
                    mobile={mobile}
                    visualization={visualization}
                    urlLink={urlLink}
                    setIframeLoaded={setIframeLoaded}
                />
            );
        } else if (
            visualization?.type !== 'GenericDashboard' &&
            urlLink !== ''
        ) {
            return (
                <iframe
                    src={urlLink}
                    width="100%"
                    frameBorder="0"
                    className={styles.iframeStyles}
                    onLoad={(): void => {
                        setIframeLoaded(true);
                    }}
                />
            );
        } else {
            return (
                <GenericDashboard
                    setIframeLoaded={setIframeLoaded}
                    mobile={mobile}
                />
            );
        }
    };

    if (mobile) {
        return (
            <div className={styles.mobileLayout}>
                {(loading || !iframeLoaded) && (
                    <div className={styles.loaderContainer}>
                        <Loader />
                    </div>
                )}
                <div className={styles.mobileTabs}>
                    <Tabs setVisualization={handleVisulizationChange} />
                </div>
                <div className={styles.mobileIframeContainer}>
                    {renderIframe()}
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.standardLayout}>
                <div className={styles.visualizationCard}>
                    {(loading || !iframeLoaded) && (
                        <div className={styles.loaderContainer}>
                            <Loader />
                        </div>
                    )}
                    {(visualization?.description?.includes('filters') ||
                        visualization?.type !== 'GenericDashboard') && (
                        <h1 className={styles.vizTitle}>
                            {visualization?.title}
                        </h1>
                    )}
                    <div className={styles.iframeContainer}>
                        {renderIframe()}
                    </div>

                    <div className={styles.tabs}>
                        <Tabs setVisualization={handleVisulizationChange} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Analytics;
