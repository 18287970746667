import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import TitleIcon from '@material-ui/icons/Title';
import SubjectIcon from '@material-ui/icons/Subject';
import { MenuItem } from '@material-ui/core';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../RoundedSelect/RoundedSelect';
import React from 'react';
import EntityProperties from '../../../constants/Entities/EntityPropertyTypes';
import styles from './RoundedEntityPropertyPicker.module.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { EntityProperty } from '../../../@Types/EntityTypes/EntityProperty';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import { Entity } from '../../../@Types/@Types';
import EntityIcon from '../../SmartIcons/EntityIcon';
import EntityPropertyTypes from '../../../constants/Entities/EntityPropertyTypes';
import { EntityIntegrationTypes } from '../../../constants/Entities/EntityIntegrationTypes';
import { EntityApiTypes } from '../../../constants/Entities/EntityApiTypes';
import SmartIcon from '../../SmartIcons/SmartIcon';
import ApiIcon from '../../../Icons/ApiIcon';
import ScheduleRounded from '@material-ui/icons/ScheduleRounded';

interface RoundedEntityPropertyPickerProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    /** The entity to display properties */
    entity: Entity;
    /** Currently selected entityProperty */
    value: string | undefined;
    /** The possible properties to select */
    properties?: string[];
    integrations?: EntityIntegrationTypes[];
    /** The posible properties types to select */
    propertyTypes?: EntityProperties[];
    /** function called when value changes */
    handleUpdate: (idProperty: string, propertyType: EntityProperties) => void;
    /** If should should public properties only */
    publicProperties?: boolean;
}
function RoundedEntityPropertyPicker({
    value,
    entity,
    properties = [],
    integrations = [],
    propertyTypes = [],
    handleUpdate,
    publicProperties = false,
    ...others
}: RoundedEntityPropertyPickerProps): JSX.Element {
    return (
        <RoundedSelect
            value={value}
            paddingLeft={0}
            containerMargin="0px"
            handleUpdate={(event): void => {
                const property = entity.steps[event.target.value ?? ''];
                if (property) {
                    handleUpdate(property.id, property.type);
                } else {
                    const api = entity.apis?.find(
                        (api) => api.id === event.target.value
                    );
                    if (api) handleUpdate(api.id, EntityProperties.API);
                }
            }}
            {...others}
        >
            {properties
                .filter(
                    (idProperty) =>
                        (propertyTypes.length === 0 ||
                            propertyTypes.includes(
                                entity.steps[idProperty]
                                    ?.type as EntityPropertyTypes
                            )) &&
                        (!publicProperties || (publicProperties as any).public)
                )
                .map((idProperty) => {
                    const property = entity.steps[idProperty];
                    if (!property) return <></>;
                    const integration = integrations.find((integration) =>
                        entity.integrations[integration]?.steps.includes(
                            property.id
                        )
                    );
                    switch (integration) {
                        case EntityIntegrationTypes.CBR_ID:
                        case EntityIntegrationTypes.CBR_ID_INMUEBLE:
                            if (
                                property.type ===
                                    EntityPropertyTypes.SEPARATOR ||
                                property.type === EntityPropertyTypes.TITLE
                            )
                                return <div></div>;
                            return (
                                <MenuItem
                                    key={property.id}
                                    value={property.id}
                                    style={{
                                        paddingLeft: 0,
                                    }}
                                >
                                    <div className={styles.property}>
                                        <div className={styles.icon}>
                                            <img
                                                className={styles.sincoIcon}
                                                src="/media/icons/sinco-cbr.svg"
                                            />
                                        </div>
                                        {property.label}
                                    </div>
                                </MenuItem>
                            );

                        default:
                            return (
                                <MenuItem
                                    key={property.id}
                                    value={property.id}
                                    style={{
                                        paddingLeft: 0,
                                    }}
                                >
                                    <PropertyComponent property={property} />
                                </MenuItem>
                            );
                    }
                })}
            {properties.length === 0 &&
                Object.values(entity?.steps ?? {})
                    .filter(
                        (step) =>
                            propertyTypes.includes(step.type) &&
                            (!publicProperties || (step as any).public)
                    )
                    .map((property) => {
                        return (
                            <MenuItem
                                key={property.id}
                                value={property.id}
                                style={{
                                    paddingLeft: 0,
                                }}
                            >
                                <PropertyComponent property={property} />
                            </MenuItem>
                        );
                    })}
            {propertyTypes.includes(EntityPropertyTypes.API) &&
                !publicProperties &&
                entity.apis
                    ?.filter(
                        (api) =>
                            (api.type === EntityApiTypes.CONDITION ||
                                api.existsCondition) &&
                            entity.steps[api.id] === undefined
                    )
                    .map((api) => (
                        <MenuItem
                            key={api.id}
                            value={api.id}
                            style={{
                                paddingLeft: 0,
                            }}
                        >
                            <div className={styles.property}>
                                <div className={styles.icon}>
                                    {api.icon ? (
                                        <SmartIcon icon={api.icon} />
                                    ) : (
                                        <ApiIcon />
                                    )}
                                </div>
                                {api.label}
                            </div>
                        </MenuItem>
                    ))}
        </RoundedSelect>
    );
}
export default RoundedEntityPropertyPicker;

export function PropertyComponent({
    property,
}: {
    property: EntityProperty;
}): JSX.Element {
    switch (property.type) {
        case EntityProperties.NAME: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.nameLbl}>
                            <TitleIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.TITLE: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.nameLbl}>
                            <TitleIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.title}
                </div>
            );
        }
        case EntityProperties.TEXTINPUT: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.textInputContainer}>A..</div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.SELECTOR: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.selectInputContainer}>
                            <ArrowDropDownIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.DATEPICKER: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.dateInputContainer}>
                            <CalendarTodayRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.TIMEPICKER: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.dateInputContainer}>
                            <ScheduleRounded fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.CLIENTPICKER:
        case EntityProperties.AGENTPICKER: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.agentInputContainer}>
                            <PersonRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.TEXTAREA: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.textAreaContainer}>
                            <SubjectIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.CHECKBOX: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.checkBoxIcon}>
                            <CheckBoxRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.ENTITY_RELATIONSHIP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.entityIcon}>
                            <EntityIcon idEntity={property.idEntity} />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        default:
            return <div></div>;
    }
}
