import { Entity } from '../../../../@Types/@Types';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import RoundedEntityPropertyPicker from '../../../@Pickers/RoundedEntityPropertyPicker/RoundedEntityPropertyPicker';
import styles from './EntityValueFilter.module.css';
import EntityPropertyTypes from '../../../../constants/Entities/EntityPropertyTypes';
import React from 'react';
import { ValueFilterComponent } from '../../../../pages/EntityValues/EntityValueFilters/EntityValueFiltersMenu/ValueFilters/ValueFilters';
import RelationshipEntityValueFilter from './EntityEntityValueFilter/RelationshipEntityValueFilter';
import { EntityValuePickerFilter } from '../../../../@Types/GenericFormSteps';
import { EntityValueFilterTypes } from '../../../../constants/FormStepTypes';
import { EntityIntegrationTypes } from '../../../../constants/Entities/EntityIntegrationTypes';

interface EntityValueFilterProps {
    /** The id of the current entity being edited (EntityEditor) */
    idEntity?: string;
    filter: EntityValuePickerFilter;
    integrations?: EntityIntegrationTypes[];
    handleUpdate: (filter: EntityValuePickerFilter) => void;
    renderStepPicker?: (
        idStep: string,
        handleUpdate: (steps: string) => void
    ) => JSX.Element;
    handleDelete: () => void;
    entity: Entity;
    properties: string[];
    showRequired?: boolean;
}

function EntityValueFilter({
    idEntity,
    entity,
    filter,
    properties,
    integrations,
    handleDelete,
    handleUpdate,
    renderStepPicker,
    showRequired = true,
}: EntityValueFilterProps): JSX.Element {
    const renderFilter = (): JSX.Element | void => {
        const property = entity.steps[filter.idProperty];
        if (!property) return;
        switch (property.type) {
            case EntityPropertyTypes.ENTITY_RELATIONSHIP:
                return (
                    <RelationshipEntityValueFilter
                        filter={filter}
                        property={property}
                        handleUpdate={(
                            filter: EntityValuePickerFilter
                        ): void => {
                            handleUpdate(filter);
                        }}
                        showRequired={showRequired}
                    />
                );
            case EntityPropertyTypes.SELECTOR:
                if (filter.type === EntityValueFilterTypes.VALUE)
                    return (
                        <div className={styles.valueInput}>
                            <ValueFilterComponent
                                idEntity={entity._id}
                                step={property}
                                value={filter.value}
                                handleChange={(value): void => {
                                    handleUpdate({ ...filter, value });
                                }}
                            />
                        </div>
                    );
                else return;
            default:
                if (
                    renderStepPicker &&
                    entity.integrations[
                        EntityIntegrationTypes.CBR_ID_INMUEBLE
                    ]?.steps.includes(property.id)
                ) {
                    if (
                        filter.type !== EntityValueFilterTypes.INTEGRATION ||
                        filter.integration !==
                            EntityIntegrationTypes.CBR_ID_INMUEBLE
                    )
                        return <div></div>;
                    return (
                        <div className={styles.stepPickerContainer}>
                            {renderStepPicker(
                                filter.idIdentifierStep,
                                (idStep) => {
                                    handleUpdate({
                                        ...filter,
                                        idIdentifierStep: idStep,
                                    });
                                }
                            )}
                        </div>
                    );
                }
                break;
        }
    };
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div className={styles.bullet}></div>
                <div>
                    <RoundedEntityPropertyPicker
                        entity={entity}
                        properties={properties}
                        integrations={integrations}
                        propertyTypes={[
                            EntityPropertyTypes.TEXTINPUT,
                            EntityPropertyTypes.ENTITY_RELATIONSHIP,
                            EntityPropertyTypes.SELECTOR,
                        ]}
                        handleUpdate={(idProperty): void => {
                            const property = entity.steps?.[idProperty ?? ''];
                            if (
                                property.type ===
                                EntityPropertyTypes.ENTITY_RELATIONSHIP
                            ) {
                                if (property.idEntity === idEntity) {
                                    handleUpdate({
                                        idProperty,
                                        type: EntityValueFilterTypes.ENTITY_RELATIONSHIP,
                                    });
                                } else {
                                    handleUpdate({
                                        idProperty,
                                        type: EntityValueFilterTypes.STEP,
                                        any: true,
                                        required: false,
                                    });
                                }
                            } else {
                                const integration = integrations?.find(
                                    (integration) =>
                                        entity.integrations[
                                            integration
                                        ]?.steps.includes(idProperty)
                                );
                                if (integration) {
                                    handleUpdate({
                                        idProperty,
                                        type: EntityValueFilterTypes.INTEGRATION,
                                        integration,
                                    } as any);
                                } else {
                                    handleUpdate({
                                        idProperty,
                                        type: EntityValueFilterTypes.VALUE,
                                        value: [],
                                    });
                                }
                            }
                        }}
                        value={filter.idProperty}
                    />
                </div>
                <div className={styles.deleteBtn} onClick={handleDelete}>
                    <DeleteRoundedIcon fontSize="inherit" />
                </div>
            </div>
            {renderFilter()}
        </div>
    );
}
export default EntityValueFilter;
