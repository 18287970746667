// Use throughout your app instead of plain `useDispatch` and `useSelector`

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './utils/_store';
import { Project } from './@Types/Project';

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useCurrentProject = (): Project => {
    return useAppSelector(
        (state) => state.site.projects[state.site.idProject ?? '']
    );
};
