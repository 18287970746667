import { useEffect } from 'react';
import { RouterProps } from '../../../Router';
import { match, withRouter } from 'react-router-dom';
import NoMobile from '../../../shared/NoMobile/NoMobile';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '../../../controllers/GenericEditorController/GenericEditorActions';
import { Entity } from '../../../@Types/@Types';
import GenericFormEditor from '../../../shared/GenericFormEditor/GenericFormEditor';
import EntityConfig from './EntityConfig/EntityConfig';
import * as EntitiesService from '../../../controllers/EntitiesController/EntitiesService';
import { EntityProperty } from '../../../@Types/EntityTypes/EntityProperty';
import Types from '../../../constants/Entities/EntityPropertyTypes';
import {
    UniqueProperties,
    PropertyLocation,
} from '../../../@Types/EntityTypes/Entity';
import { RootState } from '../../../utils/_store';
import { EditorConfig } from '../../../controllers/GenericEditorController/GenericEditorReducer';
import ReducerFunctions from './EntityReducerFunctions';
import { EntityPropertyMapper } from './EntityPropertyMappers';
import PropertyMenu from './PropertyMenu/PropertyMenu';
import NameProperty from '../../../shared/EntityRenderer/NameProperty/NameProperty';
import EntityRelationshipPicker from '../../../shared/EntityRenderer/EntityRelationshipPickerStep/EntityRelationshipPickerStep';
import { refreshEntities } from '../../../controllers/_SiteController/SiteActions';
import { checkAdmin } from '../../../utils/PermissionsFunctions';
interface apiKeyRouteParam {
    /** Current entity */
    idEntity: string;
}

const idEditor = 'ENTITY';

function EntityEditorComponent({
    match,
    mobile,
}: {
    match: match<apiKeyRouteParam>;
} & RouterProps): JSX.Element {
    const dispatch = useDispatch();
    const siteInfo = useSelector((state: RootState) => state.site);
    useEffect(() => {
        if (match.params.idEntity) {
            dispatch(
                reset(idEditor, {
                    loadParams: [match.params.idEntity],
                    loadData: EntitiesService.loadEntity,
                    //Function to edit the initial state
                    getInfoOnSuccess: (entity: Entity) => {
                        return {
                            ...entity,
                            firstSection: 'FIRST',
                            sections: {
                                FIRST: {
                                    id: 'FIRST',
                                    steps: entity.rootSteps,
                                    name: entity.name,
                                    nextSection: null,
                                },
                            },
                        };
                    },
                    ...ReducerFunctions,
                } as EditorConfig<Entity, any, any, any, any>)
            );
        }
    }, [match.params.idEntity]);

    if (mobile) {
        return <NoMobile />;
    }
    return (
        <GenericFormEditor<
            Entity,
            EntityProperty,
            UniqueProperties,
            PropertyLocation,
            Entity
        >
            mobile={mobile}
            idEditor={idEditor}
            stepMenuComponent={<PropertyMenu />}
            configComponent={<EntityConfig />}
            editSize={checkAdmin(siteInfo.user)}
            editId={checkAdmin(siteInfo.user)}
            calcCurrentRoute={(_project, data): string => {
                const route =
                    siteInfo.organization?.name + ' / Entidades /' + data.name;
                return route;
            }}
            getFormFromData={(data): any => {
                return data;
            }}
            handleSave={async (entity, data): Promise<void> => {
                const original: any = { ...data, ...entity };
                original.rootSteps = original.sections['FIRST'].steps;
                delete original.firstSection;
                delete original.sections;
                await EntitiesService.saveEntity(data._id, original);
                dispatch(refreshEntities());
            }}
            customSteps={{
                [Types.NAME]: {
                    component: NameProperty as any,
                },
                [Types.ENTITY_RELATIONSHIP]: {
                    component: (props: any): JSX.Element => (
                        <EntityRelationshipPicker {...props} />
                    ),
                },
            }}
            stepMapper={EntityPropertyMapper}
            stepMapperProps={{}}
            multiSection={false}
        />
    );
}
export default withRouter((props: any) => <EntityEditorComponent {...props} />);
