import { useMemo } from 'react';
import {
    Entity,
    EntityIntegration,
} from '../../../../../../@Types/EntityTypes/Entity';
import { EntityIntegrationTypes } from '../../../../../../constants/Entities/EntityIntegrationTypes';
import RoundedMultiSelect from '../../../../../../shared/RoundedMultiSelect/RoundedMultiSelect';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

import styles from './EntityIntegration.module.css';
interface EntityIntegrationProps {
    entity: Entity;
    integration: EntityIntegration;
    handleUpdate: (integration: EntityIntegration) => void;
    handleDelete: () => void;
}
function EntityIntegrationComponent({
    entity,
    integration,
    handleUpdate,
    handleDelete,
}: EntityIntegrationProps): JSX.Element {
    const steps = useMemo(() => Object.keys(entity.steps), [entity]);

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div className={styles.bullet} />
                <EntityIntegrationMapper type={integration.type} />
                <div
                    className={styles.deleteBtn}
                    onClick={(): void => handleDelete()}
                >
                    <DeleteRoundedIcon fontSize="inherit" />
                </div>
            </div>
            <div className={styles.stepSelectorContainer}>
                <RoundedMultiSelect
                    label="Pasos"
                    placeholder="Pasos"
                    handleUpdate={(steps: string[]): void =>
                        handleUpdate({ ...integration, steps })
                    }
                    options={steps}
                    values={integration.steps}
                />
            </div>
        </div>
    );
}

export default EntityIntegrationComponent;

export function EntityIntegrationMapper({
    type,
}: {
    type: EntityIntegrationTypes;
}): JSX.Element {
    switch (type) {
        case EntityIntegrationTypes.CBR_ID:
            return (
                <div className={styles.cbrIntegration}>
                    <div className={styles.icon}>
                        <img
                            className={styles.sincoIcon}
                            src="/media/icons/sinco-cbr.svg"
                        />
                    </div>
                    idCBR
                </div>
            );
        case EntityIntegrationTypes.CBR_ID_INMUEBLE:
            return (
                <div className={styles.cbrIntegration}>
                    <div className={styles.icon}>
                        <img
                            className={styles.sincoIcon}
                            src="/media/icons/sinco-cbr.svg"
                        />
                    </div>
                    idCBR (Inmueble)
                </div>
            );
        default:
            return <div></div>;
    }
}
