import React, { useEffect, useState } from 'react';
import { fetchCardByName } from '../../../controllers/DashboardController/DashboardService';
import Filters, {
    AnalyticFilters,
    parseAnalyticFilters,
} from '../Filters/Filters';
import styles from './GenericDashboard.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { Metrics } from '../Filters/Metrics';
interface FrameWithFiltersProps {
    /** Function that activates de loader of the dashboard */
    setIframeLoaded: Function;
    mobile: boolean;
}

const allowedFilters = {
    dates: true,
    metric: true,
    firstClassifiers: true,
    secondClassifiers: true,
    temporality: true,
};

function GenericDashboard({
    mobile,
    setIframeLoaded,
}: FrameWithFiltersProps): JSX.Element {
    const [urlLink, setUrlLink] = useState('');
    const [filters, setFilters] = useState<AnalyticFilters>({
        metric: Metrics.CANT_CASOS,
        values: {},
        entities: {},
    });
    const [cardName, setcardName] = useState('Casos recibidos');
    const siteInfo = useSelector((state: RootState) => state.site);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        async function getUrlsAsync(): Promise<void> {
            try {
                const url = await fetchCardByName(cardName, {
                    idProject: siteInfo.idProject,
                });
                const splittedUrl = url.split('#');
                setUrlLink(splittedUrl[0]);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        }
        getUrlsAsync();
    }, [siteInfo.idProject, cardName]);

    enum VIZTYPES {
        CLASSIFIERS_TEMP = 'clasificadores por temporalidad',
        CLASSIFIERS = 'clasificadores',
        CLASSIFIER_DAY = 'clasificador por día',
        CLASSIFIER = 'clasificador',
        CASES_DAY = 'casos por día',
        CASES_TEMP = 'casos por temporalidad',
        CASES = 'casos recibidos',
    }

    const dashboards = {
        [VIZTYPES.CLASSIFIERS_TEMP]: {
            [Metrics.CANT_CASOS]:
                'Cantidad de casos por clasificadores por temporalidad',
            [Metrics.RATING]:
                'Calificación de casos por clasificadores por temporalidad',
            [Metrics.CLOSE_TIME]:
                'Tiempo de cierre de casos por clasificadores por temporalidad',
            [Metrics.REPLY_TIME]:
                'Tiempo de respuesta de casos por clasificadores por temporalidad',
        },
        [VIZTYPES.CLASSIFIERS]: {
            [Metrics.CANT_CASOS]: 'Cantidad de casos por clasificadores',
            [Metrics.RATING]: 'Calificación de casos por clasificadores',
            [Metrics.CLOSE_TIME]:
                'Tiempo de cierre de casos por clasificadores',
            [Metrics.REPLY_TIME]:
                'Tiempo de respuesta de casos por clasificadores',
        },
        [VIZTYPES.CLASSIFIER_DAY]: {
            [Metrics.CANT_CASOS]: 'Cantidad de casos por clasificador por día',
            [Metrics.RATING]: 'Calificación de casos por clasificador por día',
            [Metrics.CLOSE_TIME]:
                'Tiempo de cierre de casos por clasificador por día',
            [Metrics.REPLY_TIME]:
                'Tiempo de respuesta de casos por clasificador por día',
        },
        [VIZTYPES.CLASSIFIER]: {
            [Metrics.CANT_CASOS]: 'Cantidad de casos por clasificador',
            [Metrics.RATING]: 'Calificación de casos por clasificador',
            [Metrics.CLOSE_TIME]: 'Tiempo de cierre de casos por clasificador',
            [Metrics.REPLY_TIME]:
                'Tiempo de respuesta de casos por clasificador',
        },
        [VIZTYPES.CASES_DAY]: {
            [Metrics.CANT_CASOS]: 'Cantidad de casos por día',
            [Metrics.RATING]: 'Calificación de casos por día',
            [Metrics.CLOSE_TIME]: 'Tiempo de cierre de casos por día',
            [Metrics.REPLY_TIME]: 'Tiempo de respuesta de casos por día',
        },
        [VIZTYPES.CASES_TEMP]: {
            [Metrics.CANT_CASOS]: 'Cantidad de casos por temporalidad',
            [Metrics.RATING]: 'Calificación promedio de casos por temporalidad',
            [Metrics.CLOSE_TIME]:
                'Tiempo promedio de cierre de casos por temporalidad',
            [Metrics.REPLY_TIME]:
                'Tiempo promedio de respuesta de casos por temporalidad',
        },
        [VIZTYPES.CASES]: {
            [Metrics.CANT_CASOS]: 'Casos recibidos',
            [Metrics.RATING]: 'Calificación promedio casos',
            [Metrics.CLOSE_TIME]: 'Tiempo promedio de cierre de casos',
            [Metrics.REPLY_TIME]: 'Tiempo promedio de respuesta de casos',
        },
    } as Record<string, Record<string, string>>;

    const handleFilterChange = (filters: AnalyticFilters): void => {
        const metric = filters.metric ?? Metrics.CANT_CASOS;
        if (filters.classifier && filters.secondClassifier) {
            if (filters.temporality) {
                updateCard(dashboards[VIZTYPES.CLASSIFIERS_TEMP][metric]);
            } else updateCard(dashboards[VIZTYPES.CLASSIFIERS][metric]);
        } else if (filters.classifier || filters.secondClassifier) {
            if (filters.temporality === '$dayOfYear') {
                updateCard(dashboards[VIZTYPES.CLASSIFIER_DAY][metric]);
            } else updateCard(dashboards[VIZTYPES.CLASSIFIER][metric]);
        } else if (filters.temporality) {
            if (filters.temporality === '$dayOfYear') {
                updateCard(dashboards[VIZTYPES.CASES_DAY][metric]);
            } else {
                updateCard(dashboards[VIZTYPES.CASES_TEMP][metric]);
            }
        } else {
            updateCard(dashboards[VIZTYPES.CASES][metric]);
        }
    };

    const updateCard = (name: string): void => {
        if (cardName !== name) {
            setcardName(name);
            setLoading(true);
            setIframeLoaded(false);
        }
    };

    return (
        <React.Fragment>
            {!mobile && <h1 className={styles.vizTitle}>{cardName}</h1>}
            <Filters
                mobile={mobile}
                filters={filters}
                setFilters={(filters: AnalyticFilters): void => {
                    handleFilterChange(filters);
                    setFilters(filters);
                }}
                allowedFilters={allowedFilters}
            />
            {!loading && urlLink !== '' && (
                <iframe
                    src={
                        urlLink +
                        parseAnalyticFilters(
                            siteInfo,
                            filters,
                            allowedFilters
                        ) +
                        '#bordered=false&titled=false&hide_parameters=firstClassifiers,secondClassifiers,temporality,startDate,endDate,lvpEstadoInmueble,lvpEstadoPosventa,ariasOrigenPosventa'
                    }
                    width="100%"
                    frameBorder="0"
                    className={styles.iframeStyles}
                    scrolling="no"
                    onLoad={(): void => {
                        setIframeLoaded(true);
                    }}
                />
            )}
        </React.Fragment>
    );
}

export default GenericDashboard;
