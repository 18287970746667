import Dialog from '../../../shared/Dialog/Dialog';
import DecisionDialog from './DecisionDialog/DecisionDialog';
import TransformationDialog from './TransformationDialog/TransformationDialog';
import AssignDialog from './AssignDialog/AssignDialog';
import { isEdge, Node, Edge } from 'react-flow-renderer';
import { AutomaticTransformation } from '../../../@Types/FlowTypes/NodeTypes/TransformationNode';
import { TicketPropertyTypes } from '../../../constants/TicketPropertyTypes';
import AIDialog from './AIDialog/AIDialog';
import CommunicationDialog from './CommunicationDialog/CommunicationDialog';
import FlowTypes from '../../../constants/Flows/FlowTypes';
import FlowNodeTypes from '../../../constants/Flows/FlowNodeTypes';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
} from '../../../controllers/PayloadEditorController/PayloadEditorSlice';
import { Payload } from '../../../@Types/Payload';
import { useState, useCallback } from 'react';
export type DialogObjType =
    | { id: string; type: string | undefined }
    | undefined;
interface FlowsDialog {
    idFlow: string;
    /** The node to display */
    dialogObj: DialogObjType;
    /** Function called to update react-flowsElements */
    setElements: React.Dispatch<
        React.SetStateAction<(Node<any> | Edge<any>)[]>
    >;
    /** ReactFlows current elements */
    elements: (Node | Edge)[];
    /** Function to update the dialogObj */
    setDialogObj: Function;
    /** The type of the current flow */
    flowType: FlowTypes;
}
function FlowsDialog({
    idFlow,
    flowType,
    dialogObj,
    setElements,
    elements,
    setDialogObj,
}: FlowsDialog): JSX.Element {
    const [submit, setSubmit] =
        useState<() => Promise<Record<string, any> | void>>();

    const handleSetSubmit = useCallback((submit): void => {
        setSubmit(() => submit);
    }, []);
    const dispatch = usePayloadEditorDispatch();
    if (dialogObj?.type === FlowNodeTypes.DECISION) {
        return (
            <DecisionDialog
                handleClose={(): void => setDialogObj(undefined)}
                flowType={flowType}
                handleUpdate={(clb): void => {
                    setElements((elements) =>
                        elements.map((element) =>
                            element.id === dialogObj.id
                                ? {
                                      ...element,
                                      data: clb(element.data as any),
                                  }
                                : element
                        )
                    );
                }}
                handleDeleteHandle={(clb): void => {
                    let idHandle = '';
                    setElements((elements) =>
                        elements
                            .map((element) => {
                                if (element.id === dialogObj.id) {
                                    const data = clb(element.data as any);
                                    idHandle = data.idHandle;
                                    return {
                                        ...element,
                                        data: data.decision,
                                    };
                                } else {
                                    return element;
                                }
                            })
                            .filter(
                                (element) =>
                                    !(
                                        isEdge(element) &&
                                        element.sourceHandle ===
                                            dialogObj.id + '-' + idHandle
                                    )
                            )
                    );
                }}
                decision={
                    elements.find((element) => element.id === dialogObj?.id)
                        ?.data
                }
            />
        );
    } else if (dialogObj?.type === FlowNodeTypes.TRANSFORMATION) {
        return (
            <Dialog
                open={dialogObj !== undefined}
                disableEnforceFocus
                onClose={async (): Promise<void> => {
                    const transformation: AutomaticTransformation =
                        elements.find(
                            (element) => element.id === dialogObj?.id
                        )?.data;
                    if (submit) {
                        let values: Record<string, any> | void;
                        if (submit) {
                            values = await submit();
                            if (!values) return;
                        }
                        setElements(
                            elements.map((element) =>
                                element.id === dialogObj.id
                                    ? {
                                          ...element,
                                          data: {
                                              ...element.data,
                                              values,
                                          },
                                      }
                                    : element
                            )
                        );
                    }
                    if (
                        dialogObj.id &&
                        transformation.property ===
                            TicketPropertyTypes.SUBTICKET &&
                        transformation.content
                    ) {
                        const payload: Payload | null = await dispatch(
                            PayloadEditorActions.calcPayload({
                                idEditor: `Flow-${idFlow}-${dialogObj.id}`,
                            }) as any
                        ).unwrap();
                        if (payload) {
                            setElements(
                                elements.map((element) =>
                                    element.id === dialogObj.id
                                        ? {
                                              ...element,
                                              data: {
                                                  ...element.data,
                                                  content: payload,
                                              },
                                          }
                                        : element
                                )
                            );
                            setDialogObj(undefined);
                        }
                    } else {
                        setDialogObj(undefined);
                    }
                }}
                border={20}
                maxWidth="80vw"
            >
                <TransformationDialog
                    flowType={flowType}
                    handleUpdate={(val: any): void => {
                        setElements(
                            elements.map((element) =>
                                element.id === dialogObj.id
                                    ? { ...element, data: val }
                                    : element
                            )
                        );
                    }}
                    setSubmit={handleSetSubmit}
                    transformation={
                        elements.find((element) => element.id === dialogObj?.id)
                            ?.data
                    }
                />
            </Dialog>
        );
    } else if (dialogObj?.type === FlowNodeTypes.ASSIGN) {
        return (
            <Dialog
                open={dialogObj !== undefined}
                disableEnforceFocus
                onClose={(): void => setDialogObj(undefined)}
                border={20}
                maxWidth="80vw"
            >
                <AssignDialog
                    handleAutoActivated={(val: any): void => {
                        const copy = elements.map((element) =>
                            element.id === dialogObj.id
                                ? { ...element, data: val }
                                : element
                        );
                        setElements(
                            copy.filter(
                                (element) =>
                                    !(
                                        isEdge(element) &&
                                        element.sourceHandle ===
                                            FlowNodeTypes.ASSIGN &&
                                        element.source === dialogObj.id
                                    )
                            )
                        );
                    }}
                    handleUpdate={(val: any): void => {
                        setElements(
                            elements.map((element) =>
                                element.id === dialogObj.id
                                    ? { ...element, data: val }
                                    : element
                            )
                        );
                    }}
                    assign={
                        elements.find((element) => element.id === dialogObj?.id)
                            ?.data
                    }
                />
            </Dialog>
        );
    } else if (dialogObj?.type === FlowNodeTypes.AI) {
        return (
            <Dialog
                open={dialogObj !== undefined}
                disableEnforceFocus
                onClose={(): void => setDialogObj(undefined)}
                border={20}
                maxWidth="80vw"
            >
                <AIDialog
                    handleUpdate={(val: any): void => {
                        setElements(
                            elements.map((element) =>
                                element.id === dialogObj.id
                                    ? { ...element, data: val }
                                    : element
                            )
                        );
                    }}
                    data={
                        elements.find((element) => element.id === dialogObj?.id)
                            ?.data
                    }
                />
            </Dialog>
        );
    } else if (dialogObj?.type === FlowNodeTypes.COMMUNICATION) {
        return (
            <Dialog
                open={dialogObj !== undefined}
                disableEnforceFocus
                onClose={async (): Promise<void> => {
                    const payload: Payload | null = await dispatch(
                        PayloadEditorActions.calcPayload({
                            idEditor: `Flow-${idFlow}-${dialogObj.id}`,
                        }) as any
                    ).unwrap();
                    if (payload) {
                        setElements(
                            elements.map((element) =>
                                element.id === dialogObj.id
                                    ? {
                                          ...element,
                                          data: {
                                              ...element.data,
                                              payload: payload,
                                          },
                                      }
                                    : element
                            )
                        );
                        setDialogObj(undefined);
                    }
                }}
                border={20}
                maxWidth="80vw"
            >
                <CommunicationDialog
                    idFlow={idFlow}
                    flowType={flowType}
                    handleUpdate={(val: any): void => {
                        setElements(
                            elements.map((element) =>
                                element.id === dialogObj.id
                                    ? { ...element, data: val }
                                    : element
                            )
                        );
                    }}
                    nodeId={dialogObj.id}
                    data={
                        elements.find((element) => element.id === dialogObj?.id)
                            ?.data
                    }
                />
            </Dialog>
        );
    } else {
        return <div></div>;
    }
}
export default FlowsDialog;
