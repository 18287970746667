import { useSelector } from 'react-redux';
import { Entity } from '../../../../../@Types/@Types';
import { RootState } from '../../../../../utils/_store';
import { EntityIntegrationTypes } from '../../../../../constants/Entities/EntityIntegrationTypes';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import React, { useMemo, useRef, useState } from 'react';
import styles from './EntityIntegrations.module.css';
import produce from 'immer';
import { ClickAwayListener, Popper } from '@material-ui/core';
import EntityIntegration, {
    EntityIntegrationMapper,
} from './EntityIntegration/EntityIntegration';

interface EntityIntegrationsProps {
    entity: Entity;
    handleUpdate: (integrations: Entity['integrations']) => void;
}

function EntityIntegrations({
    entity,
    handleUpdate,
}: EntityIntegrationsProps): JSX.Element {
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );

    const btnRef = useRef<any>();

    const [showAddIntegration, setShowAddIntegration] = useState(false);

    const possibleIntegrations = useMemo(() => {
        const integrations = [];
        if (
            organization?.integrations.CBR &&
            !entity.integrations?.[EntityIntegrationTypes.CBR_ID_INMUEBLE]
        ) {
            integrations.push(EntityIntegrationTypes.CBR_ID_INMUEBLE);
        }
        return integrations;
    }, [entity.integrations]);
    if (
        possibleIntegrations.length === 0 &&
        Object.keys(entity.integrations).length === 0
    )
        return <div></div>;

    return (
        <React.Fragment>
            <div className={styles.descriptionLabel + ' noselect'}>
                Integraciones:
            </div>
            {Object.values(entity.integrations).map((integration, index) => (
                <EntityIntegration
                    key={index}
                    entity={entity}
                    integration={integration}
                    handleUpdate={(integration): void =>
                        handleUpdate({
                            ...entity.integrations,
                            [integration.type]: integration,
                        })
                    }
                    handleDelete={(): void => {
                        handleUpdate(
                            produce(entity.integrations, (integrations) => {
                                if (integrations)
                                    delete integrations[integration.type];
                            })
                        );
                    }}
                />
            ))}
            {showAddIntegration && (
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={(): void => setShowAddIntegration(false)}
                >
                    <Popper
                        open={true}
                        anchorEl={btnRef.current}
                        placement={'bottom-start'}
                        modifiers={{
                            preventOverflow: {
                                enabled: true,
                                priority: ['top', 'bottom', 'left', 'right'],
                                boundariesElement: 'viewport',
                                padding: 20,
                            },
                        }}
                        style={{ zIndex: 1310 }}
                    >
                        <div className={styles.menuContainer}>
                            {possibleIntegrations.map((integration) => (
                                <div
                                    onClick={(): void => {
                                        handleUpdate({
                                            ...entity.integrations,
                                            [integration]: {
                                                type: integration,
                                                steps: [],
                                            },
                                        });
                                        setShowAddIntegration(false);
                                    }}
                                >
                                    <EntityIntegrationMapper
                                        type={integration}
                                        key={integration}
                                    />
                                </div>
                            ))}
                        </div>
                    </Popper>
                </ClickAwayListener>
            )}
            {possibleIntegrations.length > 0 && (
                <div
                    className={styles.addIntegrationsBtn}
                    onClick={(): void => setShowAddIntegration(true)}
                    ref={btnRef}
                >
                    <div className={styles.addIconContainer}>
                        <AddRoundedIcon fontSize="inherit" />
                    </div>
                    <div className={styles.addIntegrationsLbl + ' noselect'}>
                        Agregar
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default EntityIntegrations;
