import { OptionTypes } from '../../../constants/OptionTypes';
import { nanoid } from 'nanoid';
import {
    GenericEditorState,
    StepDependencies,
} from '../../../controllers/GenericEditorController/GenericEditorReducer';
import { Entity } from '../../../@Types/@Types';
import { EntityProperty } from '../../../@Types/EntityTypes/EntityProperty';
import {
    PropertyLocation,
    UniqueProperties,
} from '../../../@Types/EntityTypes/Entity';
import PropertyTypes from '../../../constants/Entities/EntityPropertyTypes';
import EntityPropertyTypes from '../../../constants/Entities/EntityPropertyTypes';
import produce from 'immer';
import { Section } from '../../../@Types/GenericForm';
import LocationTypes from '../../../constants/LocationTypes';
import { useGenericEditorSelector } from '../../../shared/GenericFormEditor/GenericFormEditorHooks';
import { TypedUseSelectorHook } from 'react-redux';
export const useEntityEditorSelector =
    useGenericEditorSelector as TypedUseSelectorHook<EditorState>;

type EditorState = GenericEditorState<
    Entity,
    EntityProperty,
    UniqueProperties,
    PropertyLocation,
    Entity
>;
function recursivelyCalcSize(
    steps: Record<string, EntityProperty>,
    idStep: string
): number {
    const step = steps[idStep];
    if (step.type === PropertyTypes.SELECTOR) {
        //Calcular el tamano recursivamente
        let maxOption = 0;
        for (const option of step.options) {
            let optionSize = 0;
            if (option.type === OptionTypes.NESTED) {
                for (const pIdStep of option.steps) {
                    optionSize += recursivelyCalcSize(steps, pIdStep);
                }
            }
            if (optionSize > maxOption) {
                maxOption = optionSize;
            }
        }
        return maxOption + step.size;
    } else if (step.type === PropertyTypes.CHECKBOX) {
        //Calcular el tamano recursivamente
        let maxSize = 0;
        if (step.steps)
            for (const pIdStep of step.steps) {
                maxSize += recursivelyCalcSize(steps, pIdStep);
            }
        let unCheckedSize = 0;
        if (step.uncheckedSteps)
            for (const pIdStep of step.uncheckedSteps) {
                unCheckedSize += recursivelyCalcSize(steps, pIdStep);
            }
        if (unCheckedSize > maxSize) {
            maxSize = unCheckedSize;
        }
        return maxSize + step.size;
    } else {
        const nestedStep = step as any;
        if (nestedStep.steps !== undefined && Array.isArray(nestedStep.steps)) {
            //Calcular el tamano recursivamente
            let stepSize = 0;
            for (const pIdStep of nestedStep.steps) {
                stepSize += recursivelyCalcSize(steps, pIdStep);
            }
            return stepSize + nestedStep.size;
        } else {
            return 4;
        }
    }
}

const handleAddStep = (
    state: EditorState,
    { step, location }: { step: EntityProperty; location: PropertyLocation }
): EditorState =>
    produce(state, (state) => {
        if (step.type === EntityPropertyTypes.ENTITY_RELATIONSHIP) {
            for (const relationship of (state.data as Entity).relationships) {
                if (relationship.idEntity === step.idEntity) {
                    relationship.idRelationshipSteps.push(step.id);
                }
            }
        }
        const subSteps = calcLocationSteps(state, location);
        if (subSteps) {
            subSteps.push(step.id);
        }
        state.steps[step.id] = step;
    });

const handleDeleteStep = (
    state: EditorState,
    { idStep, location }: { idStep: string; location: PropertyLocation }
): EditorState =>
    produce(state, (state) => {
        const step = state.steps[idStep];
        if (step?.type === EntityPropertyTypes.ENTITY_RELATIONSHIP) {
            for (const relationship of (state.data as Entity).relationships) {
                relationship.idRelationshipSteps =
                    relationship.idRelationshipSteps.filter(
                        (id) => id !== idStep
                    );
            }
        }

        delete state.steps[idStep];

        if (location.indexStep === null) return;
        const subSteps = calcLocationSteps(state, location);
        if (subSteps) {
            subSteps.splice(location.indexStep, 1);
        }
    });

function cloneStep(
    idStep: string,
    steps: Record<string, EntityProperty>,
    newSteps: Record<string, EntityProperty>
): string {
    const tempStep = { ...steps[idStep] };
    const newId = tempStep.type + '-' + nanoid();
    tempStep.id = newId;
    if (tempStep.type === PropertyTypes.SELECTOR) {
        tempStep.options = tempStep.options.map((option) => {
            const tempOption = { ...option };
            if (tempOption.type === OptionTypes.NESTED) {
                tempOption.steps.map((pStep) =>
                    cloneStep(pStep, steps, newSteps)
                );
            }
            return tempOption;
        });
    } else if (tempStep.type === PropertyTypes.MAPPER) {
        tempStep.rootSteps = tempStep.rootSteps.map((idStep: string) =>
            cloneStep(idStep, steps, newSteps)
        );
    } else {
        const nestedStep = tempStep as any;
        if (nestedStep.steps !== undefined) {
            nestedStep.steps = nestedStep.steps.map((idStep: string) =>
                cloneStep(idStep, steps, newSteps)
            );
        }
    }
    newSteps[newId] = tempStep;
    return newId;
}

const handlePasteStep = (
    state: EditorState,
    location: PropertyLocation
): EditorState =>
    produce(state, (state) => {
        if (!state.copiedInfo) return;

        const copiedInfo = state.copiedInfo;

        if (location.indexStep === null) return;

        const subSteps = calcLocationSteps(state, location);
        if (!subSteps) return;

        subSteps.splice(location.indexStep + 1, 0, copiedInfo.newStepId);

        for (const newStep of Object.values(copiedInfo.newSteps)) {
            state.steps[newStep.id] = newStep;
        }

        /** Make a new Copy */
        const newSteps = {};
        const newIdStep = cloneStep(
            copiedInfo.newStepId,
            copiedInfo.newSteps,
            newSteps
        );
        copiedInfo.newStepId = newIdStep;
        copiedInfo.newSteps = newSteps;
    });

const handleMoveStepUp = (
    state: EditorState,
    location: PropertyLocation
): EditorState =>
    produce(state, (state) => {
        if (location.indexStep === null || location.indexStep < 1) return;
        const i = location.indexStep;
        const steps = calcLocationSteps(state, location);
        if (steps) {
            [steps[i], steps[i - 1]] = [steps[i - 1], steps[i]];
        }
    });

const handleMoveStepDown = (
    state: EditorState,
    location: PropertyLocation
): EditorState =>
    produce(state, (state) => {
        if (location.indexStep === null) return;
        const i = location.indexStep;
        const steps = calcLocationSteps(state, location);
        if (steps && location.indexStep < steps.length - 1) {
            [steps[i], steps[i + 1]] = [steps[i + 1], steps[i]];
        }
    });

const handleUpdateStep = (
    state: EditorState,
    { step }: { step: EntityProperty }
): EditorState =>
    produce(state, (state) => {
        state.steps[step.id] = step;
    });

const handleUpdateSteps = (
    state: EditorState,
    { steps }: { steps: Record<string, EntityProperty> }
): EditorState =>
    produce(state, (state) => {
        state.steps = { ...state.steps, ...steps };
    });

const handleUpdateStepId = (
    state: EditorState,
    {
        location,
        idStep,
        newId,
    }: {
        idStep: string;
        newId: string;
        location: PropertyLocation;
    }
): EditorState =>
    produce(state, (state) => {
        state.steps[newId] = {
            ...state.steps[idStep],
            id: newId,
        };
        delete state.steps[idStep];

        if (location.indexStep === null) return;
        const subSteps = calcLocationSteps(state, location);
        if (subSteps) {
            subSteps[location.indexStep] = newId;
        }

        if (state.data.relationships) {
            for (const relationship of state.data.relationships) {
                relationship.idRelationshipSteps =
                    relationship.idRelationshipSteps.map((id) =>
                        id === idStep ? newId : id
                    );
            }
        }
    });

export const calcLocationSteps = (
    state: EditorState,
    location: PropertyLocation
): string[] | void => {
    if (location.type === LocationTypes.SECTION) {
        return state.sections[state.idSection!].steps;
    }

    /** Is el papa es el mapper saque el papa del dict principal */
    const parentStep = state.steps[location.idStep];

    if (
        parentStep.type === PropertyTypes.SELECTOR &&
        location.type === LocationTypes.SELECTOR
    ) {
        const option = parentStep.options[location.indexOption];
        if (option.type === OptionTypes.NESTED) {
            return option.steps;
        }
    } else if (
        parentStep.type === PropertyTypes.CHECKBOX &&
        location.type === LocationTypes.CHECKBOX
    ) {
        return location.checked ? parentStep.steps : parentStep.uncheckedSteps;
    } else if (
        parentStep.type === PropertyTypes.MAPPER &&
        location.type === LocationTypes.MAPPER
    ) {
        return parentStep.rootSteps;
    } else {
        const nestedStep = parentStep as any;
        if (nestedStep.steps !== undefined) {
            return nestedStep.steps;
        }
    }
};

const calcDependencies = (state: EditorState): EditorState => {
    const newStepDeps = {};
    const currentDeps: StepDependencies = {
        ancestors: [],
        dependencies: {},
        previousSteps: [],
        idParent: null,
    };
    let idSection: string | null = state.firstSection;

    while (idSection !== null) {
        const section: Section = state.sections[idSection];
        for (const idStep of section.steps) {
            calcStepDeps(idStep, state.steps, newStepDeps, currentDeps, null);
        }
        idSection = section.nextSection;
    }
    return { ...state, stepDependencies: newStepDeps };
};

const calcStepDeps = (
    idStep: string,
    steps: Record<string, EntityProperty>,
    stepDeps: Record<string, StepDependencies>,
    deps: StepDependencies,
    idParent: string | null
): void => {
    const step = steps[idStep];
    stepDeps[idStep] = {
        ancestors: idParent
            ? [...deps.ancestors, idParent]
            : [...deps.ancestors],
        dependencies: { ...deps.dependencies },
        previousSteps: [...deps.previousSteps],
        idParent,
    };
    if (!deps.dependencies[step.type]) deps.dependencies[step.type] = [];
    deps.dependencies[step.type].push(idStep);
    deps.previousSteps.push(idStep);
    switch (step.type) {
        case EntityPropertyTypes.SELECTOR: {
            for (const option of step.options) {
                const oDeps = JSON.parse(JSON.stringify(deps));
                if (option.type === OptionTypes.NESTED) {
                    for (const idSub of option.steps) {
                        calcStepDeps(idSub, steps, stepDeps, oDeps, idStep);
                    }
                }
            }
            break;
        }
        case EntityPropertyTypes.MAPPER: {
            for (const idSub of step.rootSteps) {
                calcStepDeps(idSub, step.steps, stepDeps, deps, idStep);
            }
            break;
        }
        default: {
            const nestedStep = step as any;
            if (nestedStep.steps !== undefined) {
                for (const idSub of nestedStep.steps) {
                    calcStepDeps(idSub, steps, stepDeps, deps, idStep);
                }
            } else if (nestedStep.subStep) {
                calcStepDeps(nestedStep.subStep, steps, stepDeps, deps, idStep);
            }
            break;
        }
    }
};

export default {
    recursivelyCalcSize,
    handleAddStep,
    handleDeleteStep,
    calcDependencies,
    cloneStep,
    handlePasteStep,
    handleMoveStepUp,
    handleMoveStepDown,
    handleUpdateStep,
    handleUpdateSteps,
    handleUpdateStepId,
};
