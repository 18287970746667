import {
    UniqueProperties,
    PropertyLocation,
} from '../../../@Types/EntityTypes/Entity';
import { EntityProperty } from '../../../@Types/EntityTypes/EntityProperty';
import EntityPropertyTypes from '../../../constants/Entities/EntityPropertyTypes';
import * as EntityProperties from '../../../@Types/EntityTypes/EntityProperty';
import AgentPickerStep from '../../../shared/@Steps/AgentPickerStep/AgentPickerStep';
import DatePickerStep from '../../../shared/@Steps/DatePickerStep/DatePickerStep';
import SeparatorStep from '../../../shared/@Steps/SeparatorStep/SeparatorStep';
import TextAreaStep from '../../../shared/@Steps/TextAreaStep/TextAreaStep';
import CheckBoxStep from '../../../shared/@Steps/CheckBoxStep/CheckBoxStep';
import TextInputStep from '../../../shared/@Steps/TextInputStep/TextInputStep';
import { GenericStepProps } from '../../../shared/GenericFormEditor/Step/Step';
import NameStep from './Steps/NameStep/NameStep';
import EntityRelationshipStep from './Steps/EntityRelationshipStep/EntityRelationshipStep';
import ClientPickerStep from '../../../shared/@Steps/ClientPickerStep/ClientPickerStep';
import TextInputConfig from './Steps/TextInputConfig/TextInputConfig';
import LocationTypes from '../../../constants/LocationTypes';
import { CheckBoxLocation } from '../../../@Types/FormTypes/LocationTypes';
import SelectorStep from '../../../shared/@Steps/SelectorStep/SelectorStep';
import MapperStep from '../../../shared/@Steps/MapperStep/MapperStep';
import FileUploadStep from '../../../shared/@Steps/FileUploadStep/FileUploadStep';
import TitleStep from '../../../shared/@Steps/TitleStep/TitleStep';
import TimePickerStep from '../../../shared/@Steps/TimePickerStep/TimePickerStep';
export function EntityPropertyMapper(
    props: GenericStepProps<EntityProperty, UniqueProperties, PropertyLocation>
): JSX.Element {
    const { step, location } = props;
    switch (step.type) {
        case EntityPropertyTypes.NAME: {
            return <NameStep {...props} step={step} />;
        }
        case EntityPropertyTypes.TITLE: {
            return <TitleStep {...props} step={step} />;
        }
        case EntityPropertyTypes.CHECKBOX: {
            return (
                <CheckBoxStep
                    {...props}
                    step={step as EntityProperties.CheckBox}
                    calcSubLocation={(checked: boolean): CheckBoxLocation => ({
                        type: LocationTypes.CHECKBOX,
                        idRootStep: location.idRootStep,
                        idStep: step.id,
                        indexStep: null,
                        checked,
                    })}
                />
            );
        }
        case EntityPropertyTypes.SELECTOR: {
            return <SelectorStep {...props} step={step} />;
        }
        case EntityPropertyTypes.DATEPICKER: {
            return <DatePickerStep {...props} step={step} />;
        }
        case EntityPropertyTypes.TIMEPICKER: {
            return <TimePickerStep {...props} step={step} />;
        }
        case EntityPropertyTypes.AGENTPICKER: {
            return <AgentPickerStep {...props} step={step} />;
        }
        case EntityPropertyTypes.CLIENTPICKER: {
            return <ClientPickerStep {...props} step={step} />;
        }
        case EntityPropertyTypes.TEXTAREA: {
            return <TextAreaStep {...props} step={step} />;
        }
        case EntityPropertyTypes.ENTITY_RELATIONSHIP: {
            return <EntityRelationshipStep {...props} step={step} />;
        }
        case EntityPropertyTypes.TEXTINPUT: {
            return (
                <TextInputStep
                    {...props}
                    step={step}
                    allowValidation={step.notificationType === undefined}
                    stepEqualityChecker={({ step }, { step: next }): boolean =>
                        step.notificationType === next.notificationType
                    }
                >
                    <TextInputConfig />
                </TextInputStep>
            );
        }
        case EntityPropertyTypes.FILEUPLOAD: {
            return <FileUploadStep {...props} step={step} />;
        }
        case EntityPropertyTypes.SEPARATOR: {
            return <SeparatorStep {...props} step={step} />;
        }
        case EntityPropertyTypes.MAPPER: {
            return <MapperStep {...props} step={step} />;
        }
        default:
            return <div></div>;
    }
}
