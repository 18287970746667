import GenericEntityIcon from '../../Icons/Entities/GenericEntityIcon';
import HandShakeIcon from '../../Icons/Entities/HandshakeIcon';
import IconTypes from '../../constants/IconTypes';
import ProjectIcon from '../../Icons/Construction/ProjectIcon';
import PropertyIcon from '../../Icons/Construction/PropertyIcon';
import SpaceIcon from '../../Icons/Construction/SpaceIcon';
import LeakIcon from '../../Icons/Construction/LeakIcon';
import CheckListIcon from '../../Icons/Entities/CheckListIcon';
import SupplierIcon from '../../Icons/Entities/SupplierIcon';
import IdentifierIcon from '../../Icons/IdentifierIcon';

export interface SmartIconProps {
    icon?: IconTypes;
    fill?: string;
    style?: any;
}

function SmartIcon({
    icon,
    style = {
        width: '100%',
        height: '100%',
    },
    ...others
}: SmartIconProps): JSX.Element {
    switch (icon) {
        case IconTypes.PROJECT:
            return <ProjectIcon style={style} {...others} />;
        case IconTypes.PROPERTY:
            return <PropertyIcon style={style} {...others} />;
        case IconTypes.SPACE:
            return <SpaceIcon style={style} {...others} />;
        case IconTypes.LEAK:
            return <LeakIcon style={style} {...others} />;
        case IconTypes.DOCUMENT:
            return <IdentifierIcon style={style} {...others} />;
        case IconTypes.CHECKLIST:
            return <CheckListIcon style={style} {...others} />;
        case IconTypes.SUPPLIER:
            return <SupplierIcon style={style} {...others} />;
        case IconTypes.HANDSHAKE:
            return <HandShakeIcon style={style} {...others} />;
        case IconTypes.GENERIC:
        default:
            return <GenericEntityIcon style={style} {...others} />;
    }
}
export default SmartIcon;
