import { makeStyles, Icon } from '@material-ui/core';
import RoundedGenericPicker, {
    RoundedGenericPickerProps,
} from '../RoundedGenericPicker/RoundedGenericPicker';
import { EntityValue } from '../../../@Types/EntityValue';
import {
    loadEntityValues,
    loadEntityValuesById,
} from '../../../controllers/EntityValuesController/EntityValuesService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import EntityIcon from '../../SmartIcons/EntityIcon';
import { EntityValueFilters } from '../../../controllers/EntityValuesController/EntityValuesReducer';

function RoundedEntityValuePicker({
    idEntity,
    label,
    filters,
    ...props
}: Omit<
    RoundedGenericPickerProps<EntityValue>,
    'calcLbl' | 'loadInitialType' | 'loadElements' | 'elementLabel' | 'label'
> & {
    idEntity: string;
    filters?: EntityValueFilters;
    label?: string;
}): JSX.Element {
    const entity = useSelector(
        (state: RootState) => state.site.entities[idEntity]
    );
    return (
        <RoundedGenericPicker<EntityValue>
            {...props}
            IconComponent={EntityRelationshipIcon(idEntity)}
            label={label ?? (props.multiple ? entity.pluralName : entity.name)}
            elementLabel={entity.name}
            calcLbl={(entityValue): string =>
                entityValue.values[entity.idLabelStep] ?? entity.name
            }
            loadInitialType={async (ids: string[]): Promise<EntityValue[]> => {
                return await loadEntityValuesById(idEntity, ids);
            }}
            loadElements={async (
                page: number,
                pageSize: number,
                search: string | undefined
            ): Promise<EntityValue[]> => {
                return await loadEntityValues(
                    entity,
                    page,
                    pageSize,
                    filters,
                    undefined,
                    search
                );
            }}
        />
    );
}
export default RoundedEntityValuePicker;

const EntityRelationshipIcon = (idEntity: string) =>
    function RelationshipIcon(props: any): JSX.Element {
        const useStyles = makeStyles({
            iconRoot: {
                position: 'absolute',
                right: '7px',
            },
            imageIcon: {
                height: 23,
                width: 23,
            },
        });
        const classes = useStyles();
        return (
            <Icon
                classes={{ root: classes.iconRoot }}
                className={props.className}
            >
                <EntityIcon
                    {...props}
                    className={classes.imageIcon}
                    idEntity={idEntity}
                />
            </Icon>
        );
    };
