import styles from './EntityConfig.module.css';
import EditContainer from '../../../../shared/EditContainer/EditContainer';
import React from 'react';
import { Entity } from '../../../../@Types/@Types';
import { updateName } from '../../../../controllers/GenericEditorController/GenericEditorActions';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EntityRelationships from './EntityRelationships';
import RoundedEntityPropertyPicker from '../../../../shared/@Pickers/RoundedEntityPropertyPicker/RoundedEntityPropertyPicker';
import EntityPropertyTypes from '../../../../constants/Entities/EntityPropertyTypes';
import { ValueFilterComponent } from '../../../EntityValues/EntityValueFilters/EntityValueFiltersMenu/ValueFilters/ValueFilters';
import Toggle from '../../../../shared/Toggle/Toggle';
import RoundedIconPicker from '../../../../shared/@Pickers/RoundedIconPicker/RoundedIconPicker';
import RoundedTextField from '../../../../shared/RoundedTextField/RoundedTextField';
import { nanoid } from 'nanoid';
import EntityIntegrations from './EntityIntegrations/EntityIntegrations';
import { useGenericEditorDispatch } from '../../../../shared/GenericFormEditor/GenericFormEditorHooks';
import { useEntityEditorSelector } from '../EntityReducerFunctions';
interface EntityConfigProps {
    data?: Entity;
    handleUpdate?: Function;
}
function EntityConfig({ data, handleUpdate }: EntityConfigProps): JSX.Element {
    if (data === undefined) {
        return <div></div>;
    }
    const entity = data;
    const steps = useEntityEditorSelector((editorInfo) => editorInfo.steps);
    const dispatch = useGenericEditorDispatch();
    const handleUpdateEntity = (entity: Entity): void => {
        if (handleUpdate) {
            handleUpdate(entity);
        }
    };
    const allowedFilters = Object.values(steps ?? {})
        .filter(
            (step) =>
                !entity.idFilterSteps.includes(step.id) &&
                step.id !== entity.idLabelStep
        )
        .map((step) => step.id);

    const allowedSorts = Object.values(steps ?? {})
        .filter(
            (step) =>
                !entity.idSortSteps.includes(step.id) &&
                [
                    EntityPropertyTypes.NAME,
                    EntityPropertyTypes.SELECTOR,
                    EntityPropertyTypes.TEXTINPUT,
                ].includes(step.type)
        )
        .map((step) => step.id);
    return (
        <React.Fragment>
            <div className={styles.descriptionLabel + ' noselect'}>Nombre</div>
            <div className={styles.descriptionInputContainer}>
                <EditContainer
                    object={entity}
                    field={'name'}
                    handleConfirm={(
                        _object: Omit<
                            Entity,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        field: keyof Omit<
                            Entity,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        value: string
                    ): void => {
                        if (entity) {
                            const tempEntity = { ...entity };
                            tempEntity.name = value;
                            handleUpdateEntity(tempEntity);
                            dispatch(updateName(value));
                        }
                    }}
                >
                    <div className={styles.description}>{entity.name}</div>
                    <input
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={entity.name}
                        type="text"
                    />
                </EditContainer>
            </div>
            <div className={styles.descriptionLabel}>Nombre en plural</div>
            <div className={styles.pluralNameContainer}>
                <EditContainer
                    object={entity}
                    field={'pluralName'}
                    handleConfirm={(
                        _object: Omit<
                            Entity,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        field: keyof Omit<
                            Entity,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        value: string
                    ): void => {
                        if (entity) {
                            const tempEntity = { ...entity };
                            tempEntity.pluralName = value;
                            handleUpdateEntity(tempEntity);
                            dispatch(updateName(value));
                        }
                    }}
                >
                    <div className={styles.description}>
                        {entity.pluralName}
                    </div>
                    <input
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={entity.pluralName}
                        type="text"
                    />
                </EditContainer>
            </div>
            <div className={styles.femenineContainer}>
                <div className={styles.descriptionLabel}>Femenino:</div>
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={entity.feminine}
                        onChange={(feminine): void => {
                            handleUpdateEntity({ ...entity, feminine });
                        }}
                    />
                </div>
            </div>
            <div className={styles.femenineContainer}>
                <div className={styles.descriptionLabel + ' noselect'}>
                    Icono:
                </div>
                <div>
                    <RoundedIconPicker
                        value={entity.icon}
                        handleUpdate={(icon): void => {
                            handleUpdateEntity({ ...entity, icon });
                        }}
                    />
                </div>
            </div>
            <div className={styles.descriptionLabel + ' noselect'}>
                Descripción
            </div>
            <div className={styles.descriptionInputContainer}>
                <EditContainer
                    object={entity}
                    field={'description'}
                    handleConfirm={(
                        _object: Omit<
                            Entity,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        field: keyof Omit<
                            Entity,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        value: string
                    ): void => {
                        if (entity) {
                            const tempEntity = { ...entity };
                            tempEntity.description = value;
                            handleUpdateEntity(tempEntity);
                        }
                    }}
                >
                    <div className={styles.description}>
                        {entity.description}
                    </div>
                    <input
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={entity.description}
                        type="text"
                    />
                </EditContainer>
            </div>
            <div className={styles.descriptionLabel + ' noselect'}>
                Paso Nombre:
            </div>
            <div className={styles.inputContainer}>
                <RoundedEntityPropertyPicker
                    entity={{ ...entity, steps }}
                    properties={Object.values(steps ?? {})
                        .filter((step) =>
                            [
                                EntityPropertyTypes.NAME,
                                EntityPropertyTypes.SELECTOR,
                                EntityPropertyTypes.TEXTINPUT,
                            ].includes(step.type)
                        )
                        .map((step) => step.id)}
                    value={entity.idLabelStep}
                    handleUpdate={(idLabelStep: string): void => {
                        handleUpdateEntity({ ...entity, idLabelStep });
                    }}
                />
            </div>

            <div className={styles.descriptionLabel + ' noselect'}>
                Ordenar:
            </div>
            {entity.idSortSteps?.map((idSortStep, index) => (
                <div className={styles.inputContainer} key={idSortStep}>
                    <RoundedEntityPropertyPicker
                        entity={{ ...entity, steps }}
                        properties={[...allowedSorts, idSortStep]}
                        value={idSortStep}
                        handleUpdate={(idProperty: string): void => {
                            const idSortSteps = [...entity.idSortSteps];
                            idSortSteps[index] = idProperty;
                            handleUpdateEntity({ ...entity, idSortSteps });
                        }}
                    />
                    {index !== 0 && (
                        <div
                            className={styles.deleteBtn}
                            onClick={(): void => {
                                const idSortSteps = [...entity.idSortSteps];
                                idSortSteps.splice(index, 1);
                                handleUpdateEntity({ ...entity, idSortSteps });
                            }}
                        >
                            <DeleteRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                </div>
            ))}
            {allowedSorts.length > 0 && (
                <div
                    className={styles.addPermButton}
                    onClick={(): void => {
                        handleUpdateEntity({
                            ...entity,
                            idSortSteps: [
                                ...entity.idSortSteps,
                                allowedSorts[0],
                            ],
                        });
                    }}
                >
                    <div className={styles.addIconContainer}>
                        <AddRoundedIcon fontSize="inherit" />
                    </div>
                    <div className={styles.addPermLbl + ' noselect'}>
                        Agregar
                    </div>
                </div>
            )}
            <div className={styles.descriptionLabel + ' noselect'}>
                Filtros:
            </div>
            {entity.idFilterSteps?.map((idFilterStep, index) => (
                <div key={idFilterStep} className={styles.filterContainer}>
                    <div className={styles.inputContainer}>
                        <RoundedEntityPropertyPicker
                            entity={{ ...entity, steps }}
                            properties={[...allowedFilters, idFilterStep]}
                            value={idFilterStep}
                            handleUpdate={(idProperty: string): void => {
                                const idFilterSteps = [...entity.idFilterSteps];
                                idFilterSteps[index] = idProperty;
                                handleUpdateEntity({
                                    ...entity,
                                    idFilterSteps,
                                });
                            }}
                        />
                        <div
                            className={styles.deleteBtn}
                            onClick={(): void => {
                                const idFilterSteps = [...entity.idFilterSteps];
                                idFilterSteps.splice(index, 1);
                                handleUpdateEntity({
                                    ...entity,
                                    idFilterSteps,
                                });
                            }}
                        >
                            <DeleteRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {steps[idFilterStep].type !==
                        EntityPropertyTypes.ENTITY_RELATIONSHIP && (
                        <div className={styles.valuesContainer}>
                            <ValueFilterComponent
                                label="Valor Por Defecto"
                                step={steps[idFilterStep]}
                                idEntity={entity._id}
                                handleChange={(value): void => {
                                    const defaultFilters = {
                                        ...(entity.defaultFilters ?? {}),
                                        [idFilterStep]: value,
                                    };
                                    handleUpdateEntity({
                                        ...entity,
                                        defaultFilters:
                                            Object.keys(defaultFilters).length >
                                            0
                                                ? defaultFilters
                                                : undefined,
                                    });
                                }}
                                value={entity.defaultFilters?.[idFilterStep]}
                            />
                        </div>
                    )}
                </div>
            ))}
            {allowedFilters.length > 0 && (
                <div
                    className={styles.addPermButton}
                    onClick={(): void => {
                        handleUpdateEntity({
                            ...entity,
                            idFilterSteps: [
                                ...entity.idFilterSteps,
                                allowedFilters[0],
                            ],
                        });
                    }}
                >
                    <div className={styles.addIconContainer}>
                        <AddRoundedIcon fontSize="inherit" />
                    </div>
                    <div className={styles.addPermLbl + ' noselect'}>
                        Agregar
                    </div>
                </div>
            )}
            <div className={styles.descriptionLabel + ' noselect'}>
                Permisos:
            </div>
            {entity.permissions.map((permission, index) => (
                <div className={styles.permissionContainer} key={index}>
                    <RoundedTextField
                        height="40px"
                        value={permission.name}
                        onChange={(event): void => {
                            const permissions = [...entity.permissions];
                            permissions[index] = {
                                ...permission,
                                name: event.target.value,
                            };
                            handleUpdateEntity({
                                ...entity,
                                permissions,
                            });
                        }}
                        label="Permiso"
                    />
                    <div
                        className={styles.deleteBtn}
                        onClick={(): void => {
                            const permissions = [...entity.permissions];
                            permissions.splice(index, 1);
                            handleUpdateEntity({
                                ...entity,
                                permissions,
                            });
                        }}
                    >
                        <DeleteRoundedIcon fontSize="inherit" />
                    </div>
                </div>
            ))}
            <div
                className={styles.addPermButton}
                onClick={(): void => {
                    handleUpdateEntity({
                        ...entity,
                        permissions: [
                            ...entity.permissions,
                            {
                                id: nanoid(),
                                name: '',
                            },
                        ],
                    });
                }}
            >
                <div className={styles.addIconContainer}>
                    <AddRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.addPermLbl + ' noselect'}>Agregar</div>
            </div>
            <EntityIntegrations
                entity={entity}
                handleUpdate={(integrations: Entity['integrations']): void => {
                    handleUpdateEntity({
                        ...entity,
                        integrations,
                    });
                }}
            />
            <EntityRelationships
                path={[]}
                relationships={entity.relationships}
                handleUpdate={(relationships): void => {
                    handleUpdateEntity({ ...entity, relationships });
                }}
            />
        </React.Fragment>
    );
}

export default EntityConfig;
