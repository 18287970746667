import { MenuItem } from '@material-ui/core';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../RoundedSelect/RoundedSelect';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import {
    ClientInfoTypeLabels,
    ClientInfoTypes,
} from '../../../constants/ClientInfoTypes';
import EntityPropertyTypes from '../../../constants/Entities/EntityPropertyTypes';
import { TextInput } from '../../../@Types/EntityTypes/EntityProperty';
import NotificationTypes from '../../../constants/NotificationTypes';
import styles from './RoundedClientPropertyPicker.module.css';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import WhatsappIcon from '../../../Icons/WhatsappIcon';

interface RoundedClientNotificationPickerProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    /** Currently selected clientProperty id */
    value: string | undefined;
    /** function called when value changes */
    handleUpdate: (
        idProperty: string | ClientInfoTypes.EMAIL | ClientInfoTypes.CEL
    ) => void;
}
const menuStyle = {
    paddingLeft: 0,
};

function RoundedClientNotificationPicker({
    value,
    handleUpdate,
    ...others
}: RoundedClientNotificationPickerProps): JSX.Element {
    const entity = useSelector((state: RootState) => state.site.clientEntity);

    return (
        <RoundedSelect
            value={value}
            paddingLeft={0}
            handleUpdate={(event): void => {
                const value = event.target.value ?? '';
                if (
                    value === ClientInfoTypes.EMAIL ||
                    value == ClientInfoTypes.CEL
                ) {
                    handleUpdate(value);
                } else {
                    const property = entity?.steps[value];
                    if (property) {
                        handleUpdate(property.id);
                    }
                }
            }}
            {...others}
        >
            <MenuItem style={menuStyle} value={ClientInfoTypes.EMAIL}>
                <div className={styles.property}>
                    <div className={styles.mailIcon}>
                        <MailOutlineRoundedIcon fontSize="inherit" />
                    </div>
                    {ClientInfoTypeLabels[ClientInfoTypes.EMAIL]}
                </div>
            </MenuItem>
            <MenuItem style={menuStyle} value={ClientInfoTypes.CEL}>
                <div className={styles.property}>
                    <div className={styles.whatsappIcon}>
                        <WhatsappIcon
                            fill="var(--secondary)"
                            style={{
                                height: 32,
                                width: 32,
                            }}
                        />
                    </div>
                    {ClientInfoTypeLabels[ClientInfoTypes.CEL]}
                </div>
            </MenuItem>
            {(
                Object.values(entity?.steps ?? {}).filter(
                    (property) =>
                        property.type === EntityPropertyTypes.TEXTINPUT &&
                        property.notificationType
                ) as TextInput[]
            ).map((property) => {
                switch (property.notificationType) {
                    case NotificationTypes.EMAIL:
                        return (
                            <MenuItem style={menuStyle} value={property.id}>
                                <div className={styles.property}>
                                    <div className={styles.mailIcon}>
                                        <MailOutlineRoundedIcon fontSize="inherit" />
                                    </div>
                                    {property.label}
                                </div>
                            </MenuItem>
                        );
                    case NotificationTypes.EMAIL:
                        return (
                            <MenuItem
                                style={menuStyle}
                                key={property.id}
                                value={property.id}
                            >
                                <div className={styles.property}>
                                    <div className={styles.whatsappIcon}>
                                        <WhatsappIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 32,
                                                width: 32,
                                            }}
                                        />
                                    </div>
                                    {property.label}
                                </div>
                            </MenuItem>
                        );
                    default:
                        return <div></div>;
                        break;
                }
            })}
        </RoundedSelect>
    );
}
export default RoundedClientNotificationPicker;
