import styles from './EntityRelationshipStep.module.css';
import { GenericStepProps } from '../../../../../shared/GenericFormEditor/Step/Step';
import { EntityRelationshipPicker } from '../../../../../@Types/EntityTypes/EntityProperty';
import { calcStepWidth } from '../../../../../shared/GenericFormEditor/StepFunctions';
import { useSelector } from 'react-redux';
import EntityPropertyTypes from '../../../../../constants/Entities/EntityPropertyTypes';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { RootState } from '../../../../../utils/_store';
import { useContext, useRef } from 'react';
import React from 'react';
import EntityValueFilter from '../../../../../shared/@Steps/EntityValueStep/EntityValueFilter/EntityValueFilter';
import RoundedTextField from '../../../../../shared/RoundedTextField/RoundedTextField';
import RoundedSelect from '../../../../../shared/RoundedSelect/RoundedSelect';
import { MapperStyleTypes } from '@arquimedes.co/eureka-forms/dist/constants/FormStepTypes';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import { MenuItem } from '@material-ui/core';
import EntityIcon from '../../../../../shared/SmartIcons/EntityIcon';
import RoundedButton from '../../../../../shared/RoundedButton/RoundedButton';
import { Entity } from '../../../../../@Types/EntityTypes/Entity';
import EntityRenderer from '../../../../../shared/EntityRenderer/EntityRenderer';
import RoundedEntityRelationshipPicker from '../../../../../shared/EntityRenderer/EntityRelationshipPickerStep/RoundedEntityRelationshipPicker/RoundedEntityRelationshipPicker';
import { GLocation } from '../../../../../@Types/FormTypes/LocationTypes';
import { EntityValueFilterTypes } from '../../../../../constants/FormStepTypes';
import { IdGenericEditorContext } from '../../../../../shared/GenericFormEditor/GenericFormEditor';
import { useEntityEditorSelector } from '../../EntityReducerFunctions';
function EntityRelationshipStep<
    StepType extends EntityRelationshipPicker,
    UniqueType,
    LocationType extends GLocation
>({
    size,
    step,
    editing,
    updateStep,
    widthStats,
}: GenericStepProps<StepType, UniqueType, LocationType>): JSX.Element {
    const idEditor = useContext(IdGenericEditorContext);

    const entity: Entity = useSelector((state: RootState) => {
        if (state.genericEditor['ENTITY'].data._id === step.idEntity) {
            return {
                ...state.genericEditor[idEditor].data,
                steps: state.genericEditor[idEditor].steps,
            };
        } else {
            return state.site.entities[step.idEntity];
        }
    });
    if (!entity) return <div></div>;
    const idMainEntity = useSelector(
        (state: RootState) => state.genericEditor[idEditor].data._id
    );
    const relationships = useEntityEditorSelector(
        (editorInfo) => editorInfo.data.relationships ?? []
    );

    const relationship = relationships.find(
        (relationship) => relationship.idEntity === step.idEntity
    );

    const addBtnRef = useRef<any>();
    const properties = Object.values(entity.steps).filter(
        (property) =>
            property.type === EntityPropertyTypes.ENTITY_RELATIONSHIP ||
            property.type === EntityPropertyTypes.SELECTOR
    );
    const newProperties = properties.filter(
        (property) =>
            !step.filters.find((filter) => filter.idProperty === property.id)
    );
    if (!relationship) return <div></div>;
    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta"
                        value={step.label ?? ''}
                        onChange={(e): void => {
                            updateStep({ ...step, label: e.target.value });
                        }}
                    ></RoundedTextField>
                </div>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Descripción"
                        value={step.description ?? ''}
                        onChange={(e): void => {
                            updateStep({
                                ...step,
                                description: e.target.value,
                            });
                        }}
                    ></RoundedTextField>
                </div>

                <div className={styles.inputContainer}>
                    <RoundedSelect
                        minWidth={240}
                        value={step.style?.type}
                        autoWidth={true}
                        label="Estilo de visualización"
                        handleUpdate={(event): void => {
                            updateStep({
                                ...step,
                                style: { type: event.target.value },
                            });
                        }}
                        containerMargin="0px"
                    >
                        <MenuItem value={'INPUT'}>
                            <div className={styles.property}>
                                <div className={styles.entityInputContainer}>
                                    <div className={styles.entityIconContainer}>
                                        <EntityIcon
                                            fill={'var(--secondary)'}
                                            idEntity={step.idEntity}
                                        />
                                    </div>
                                </div>
                                Selector
                            </div>
                        </MenuItem>
                        <MenuItem value={MapperStyleTypes.PILL}>
                            <div className={styles.property}>
                                <div className={styles.mapperIcon}>
                                    <PlaylistAddRoundedIcon fontSize="inherit" />
                                </div>
                                Lista
                            </div>
                        </MenuItem>
                    </RoundedSelect>
                </div>
                {properties.length > 0 && (
                    <React.Fragment>
                        <div className={styles.filtersLabel}>Filtros:</div>
                        <div className={styles.filtersContainer}>
                            {step.filters.map((filter, index) => (
                                <EntityValueFilter
                                    idEntity={idMainEntity}
                                    filter={filter}
                                    properties={[
                                        filter.idProperty,
                                        ...newProperties.map((prop) => prop.id),
                                    ]}
                                    key={index}
                                    entity={entity}
                                    handleUpdate={(filter): void => {
                                        const newFilters = [...step.filters];
                                        newFilters[index] = filter;
                                        updateStep({
                                            ...step,
                                            filters: newFilters,
                                        });
                                    }}
                                    handleDelete={(): void => {
                                        const newFilters = [...step.filters];
                                        newFilters.splice(index, 1);
                                        updateStep({
                                            ...step,
                                            filters: newFilters,
                                        });
                                    }}
                                />
                            ))}
                            {newProperties.length > 0 && (
                                <div
                                    className={styles.addOption}
                                    onClick={(): void => {
                                        const property = newProperties[0];
                                        if (
                                            property.type ===
                                                EntityPropertyTypes.ENTITY_RELATIONSHIP &&
                                            property.idEntity === idMainEntity
                                        ) {
                                            updateStep({
                                                ...step,
                                                filters: [
                                                    ...(step.filters ?? []),
                                                    {
                                                        type: EntityValueFilterTypes.ENTITY_RELATIONSHIP,
                                                        idProperty: property.id,
                                                    },
                                                ],
                                            });
                                        } else {
                                            updateStep({
                                                ...step,
                                                filters: [
                                                    ...(step.filters ?? []),
                                                    {
                                                        idProperty: property.id,
                                                        value: null,
                                                    },
                                                ],
                                            });
                                        }
                                    }}
                                    ref={addBtnRef}
                                >
                                    <div className={styles.addIconContainer}>
                                        <AddRoundedIcon fontSize="inherit" />
                                    </div>
                                    <div
                                        className={
                                            styles.addOptionLbL + ' noselect'
                                        }
                                    >
                                        Agregar Filtro
                                    </div>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    } else {
        switch (step.style?.type) {
            case MapperStyleTypes.PILL:
                return (
                    <div
                        className={styles.pillContainer}
                        style={{
                            width:
                                (size.blockSize + size.spacingSize) *
                                size.blockNum,
                        }}
                    >
                        <div className={styles.titleLbl}>{step.label}</div>
                        {step.description && (
                            <p
                                className={styles.descriptionPar}
                                style={{
                                    margin: step.description
                                        ? '10px 0px'
                                        : '0px 0px 5px 0px',
                                }}
                            >
                                {step.description}
                            </p>
                        )}
                        <div
                            className={
                                widthStats.isMobile
                                    ? styles.mobileContainer
                                    : styles.elementContainer
                            }
                        >
                            <div className={styles.contentContainer}>
                                <div className={styles.elementTitleLbl}>
                                    {`(${entity.name})`}:
                                </div>
                                <div className={styles.deleteBtn}>
                                    <DeleteRoundedIcon fontSize="inherit" />
                                </div>
                            </div>
                            <div
                                className={styles.contentContainer}
                                style={{
                                    width:
                                        (size.blockSize + size.spacingSize) *
                                        (size.blockNum - 1),
                                }}
                            >
                                <EntityRenderer
                                    editable={false}
                                    entity={relationship}
                                />
                            </div>
                        </div>
                        <div className={styles.btnContainer}>
                            <RoundedButton
                                text={'Agregar'}
                                color={'white'}
                                fontSize={'1rem'}
                                padding={'5px 15px 5px 15px'}
                            />
                        </div>
                    </div>
                );
            case 'INPUT':
            default:
                return (
                    <div
                        style={{
                            width: calcStepWidth(step.size, size),
                            maxWidth: '100%',
                            minHeight:
                                step.description || step.required
                                    ? '50px'
                                    : '38px',
                        }}
                    >
                        <RoundedEntityRelationshipPicker
                            idStep={step.id}
                            multiple={step.multiple}
                            relationship={relationship}
                            backgroundColor="var(--primary1)"
                            label={step.label}
                            helperText={step.description ?? ''}
                            required={step.required}
                            value={[]}
                        />
                    </div>
                );
        }
    }
}

export default React.memo(EntityRelationshipStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.size !== newStep.size ||
        step.idEntity !== newStep.idEntity ||
        step.label !== newStep.label ||
        step.multiple !== newStep.multiple ||
        step.required !== newStep.required ||
        step.description !== newStep.description ||
        step.filters !== newStep.filters ||
        step.style !== newStep.style
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof EntityRelationshipStep;
