/**
 * Operators available to entity properties conditions
 */
enum EntityPropertyOperators {
    EQUAL = 'EQUAL',
    NOTEQUAL = 'NOTEQUAL',
    INCLUDES = 'INCLUDES',
    NOTINCLUDES = 'NOTINCLUDES',
    LESS = 'LESS',
    MORE = 'MORE',
    EXISTS = 'EXISTS',
    NOTEXISTS = 'NOTEXISTS',
    PAST_RELATIVE_LESS = 'PAST_RELATIVE_LESS',
    PAST_RELATIVE_MORE = 'PAST_RELATIVE_MORE',
    FUTURE_RELATIVE_LESS = 'FUTURE_RELATIVE_LESS',
    FUTURE_RELATIVE_MORE = 'FUTURE_RELATIVE_MORE',
}

export default EntityPropertyOperators;
