enum EntityPropertyTypes {
    API = 'API',
    NAME = 'NAME',
    TITLE = 'TITLE',
    TEXTAREA = 'TEXTAREA',
    DATEPICKER = 'DATEPICKER',
    TIMEPICKER = 'TIMEPICKER',
    TEXTINPUT = 'TEXTINPUT',
    SEPARATOR = 'SEPARATOR',
    SELECTOR = 'SELECTOR',
    CHECKBOX = 'CHECKBOX',
    AGENTPICKER = 'AGENTPICKER',
    CLIENTPICKER = 'CLIENTPICKER',
    FILEUPLOAD = 'FILEUPLOAD',
    ENTITY_RELATIONSHIP = 'ENTITY_RELATIONSHIP',
    MAPPER = 'MAPPER',
}
export default EntityPropertyTypes;
