import styles from './ButtonsStep.module.css';
import React, { useContext, useState } from 'react';
import { ButtonsStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch } from 'react-redux';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RoundedRadio from '../../../../../../shared/RoundedRadio/RoundedRadio';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { nanoid } from 'nanoid';
import RoundedClassifierPicker from '../../../../../../shared/@Pickers/RoundedClassifierPicker/RoundedClassifierPicker';
import FilterIcon from '../../../../../../Icons/FilterIcon';
import produce from 'immer';
import Conditions from '../../Condition/Condition';
import Toggle from '../../../../../../shared/Toggle/Toggle';
import RoundedClientPropertyPicker from '../../../../../../shared/@Pickers/RoundedClientPropertyPicker/RoundedClientPropertyPicker';
import { ClientInfoTypes } from '../../../../../../constants/ClientInfoTypes';
import EntityPropertyTypes from '../../../../../../constants/Entities/EntityPropertyTypes';
import ClientPropertyTypes from '../../../../../../constants/ClientPropertyTypes';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';

interface ButtonsStepProps {
    step: ButtonsStep;
}

function ButtonsStepComponent({ step }: ButtonsStepProps): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);
    const [showConditions, setShowConditions] = useState<undefined | string>();
    return (
        <React.Fragment>
            {showConditions && (
                <Conditions
                    handleClose={(): void => {
                        setShowConditions(undefined);
                    }}
                    condition={step.options[showConditions].condition}
                    title={step.name}
                    handleUpdate={(condition): void => {
                        dispatch(
                            updateStep(
                                produce(step, (newStep) => {
                                    const option =
                                        newStep.options[showConditions];
                                    if (option) {
                                        option.condition = condition;
                                    }
                                })
                            )
                        );
                    }}
                    idStep={step.id}
                />
            )}
            <div className={styles.headerContainer}>
                <RoundedTextField
                    label="Encabezado"
                    multiline
                    maxLength={60}
                    value={step.header}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, header: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.container}>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        placeholder={'Mensaje'}
                        type={TextEditorTypes.WHATSAPP}
                        idEditor={`Conversation-${apiKey}-${step.id}`}
                        payload={{
                            draft: step.message,
                        }}
                        onChange={({ draft: message }): void => {
                            if (!message) return;
                            dispatch(updateStep({ ...step, message }));
                        }}
                        media={false}
                        context={{
                            client: true,
                        }}
                        conditions={{
                            types: BaseConversationPayloadConditionTypes,
                            context: {
                                conversation: {
                                    idStep: step.id,
                                },
                            },
                        }}
                        required
                        minHeight={144}
                        maxHeight={400}
                        maxLength={4096}
                    />
                </div>
            </div>
            <div className={styles.footerContainer}>
                <RoundedTextField
                    label="Pie"
                    multiline
                    maxLength={60}
                    value={step.footer}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, footer: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.toggleLabelContainer}>
                Asunto del Caso:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.isSubject}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({ ...step, isSubject: checked })
                            );
                        }}
                    />
                </div>
            </div>
            {/* <div className={styles.toggleLabelContainer}>
                Mostrar en la Traza:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.showInTrace}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({ ...step, showInTrace: checked })
                            );
                        }}
                    />
                </div>
            </div> */}
            <div className={styles.clientContainer}>
                <div className={styles.toggleLabelContainer}>
                    <div className={styles.clientLbl}>
                        Información del Cliente:
                    </div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={
                                step.clientInfoType !== undefined ||
                                step.idClientProperty !== undefined
                            }
                            onChange={(checked: boolean): void => {
                                const tempStep = checked
                                    ? {
                                          ...step,
                                          clientInfoType: ClientInfoTypes.NAME,
                                          recurring: checked,
                                      }
                                    : {
                                          ...step,
                                          clientInfoType: undefined,
                                          idClientProperty: undefined,
                                          recurring: undefined,
                                      };
                                dispatch(updateStep(tempStep));
                            }}
                        />
                    </div>
                </div>
                {(step.clientInfoType !== undefined ||
                    step.idClientProperty !== undefined) && (
                    <React.Fragment>
                        <div className={styles.clientInfoContainer}>
                            <div className={styles.clientInfoLabel}>Tipo: </div>
                            <div className={styles.clientInfoSelectorContainer}>
                                <RoundedClientPropertyPicker
                                    height="31px"
                                    fullWidth
                                    label={''}
                                    MenuProps={{ disablePortal: true }}
                                    value={
                                        step.clientInfoType ??
                                        step.idClientProperty
                                    }
                                    propertyTypes={[
                                        ClientPropertyTypes.CLIENT_INFO_TYPE,
                                        EntityPropertyTypes.TEXTINPUT,
                                    ]}
                                    handleUpdate={(idProperty): void => {
                                        if (
                                            Object.values(
                                                ClientInfoTypes
                                            ).includes(
                                                idProperty as ClientInfoTypes
                                            )
                                        ) {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    clientInfoType:
                                                        idProperty as ClientInfoTypes,
                                                    idClientProperty: undefined,
                                                })
                                            );
                                        } else {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    clientInfoType: undefined,
                                                    idClientProperty:
                                                        idProperty,
                                                })
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.recurringLabelContainer}>
                            <div className={styles.clientLbl}>
                                Preguntar Siempre:
                            </div>
                            <div className={styles.toggleContainer}>
                                <Toggle
                                    checked={step.recurring === true}
                                    onChange={(checked: boolean): void => {
                                        dispatch(
                                            updateStep({
                                                ...step,
                                                recurring: checked,
                                            })
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>

            <div className={styles.optionsContainer}>
                <label className={styles.lblCont}>Opciones:</label>
                {step.optionList.map((idOption) => {
                    const option = step.options[idOption];
                    return (
                        <div className={styles.optionContainer} key={idOption}>
                            <div className={styles.optionContentContainer}>
                                <div
                                    className={styles.optionRadio}
                                    title={'Seleccionar Opción'}
                                >
                                    <RoundedRadio
                                        checked={
                                            idOption === step.selectedOption
                                        }
                                        onChange={(): void => {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    selectedOption: idOption,
                                                })
                                            );
                                        }}
                                    />
                                </div>
                                <div className={styles.optionLabelInput}>
                                    <RoundedTextField
                                        label="Opción"
                                        multiline
                                        maxLength={20}
                                        value={option.label}
                                        onChange={(e): void => {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    options: {
                                                        ...step.options,
                                                        [idOption]: {
                                                            ...option,
                                                            label: e.target
                                                                .value,
                                                        },
                                                    },
                                                })
                                            );
                                        }}
                                    ></RoundedTextField>
                                </div>
                                <div className={styles.btnsContainer}>
                                    <div
                                        className={
                                            styles.conditionsBtnContainer
                                        }
                                        onClick={(): void => {
                                            setShowConditions(option.id);
                                        }}
                                    >
                                        <FilterIcon
                                            style={{
                                                display: 'flex',
                                                height: 18,
                                                width: 18,
                                                marginTop: 2,
                                            }}
                                            fill="inherit"
                                        />
                                    </div>
                                    {step.optionList.length > 1 && (
                                        <div
                                            className={styles.deleteBtn}
                                            onClick={(): void => {
                                                const newStep = produce(
                                                    step,
                                                    (tempStep) => {
                                                        tempStep.optionList =
                                                            tempStep.optionList.filter(
                                                                (opt) =>
                                                                    opt !==
                                                                    idOption
                                                            );

                                                        delete tempStep.options[
                                                            idOption
                                                        ];
                                                        if (
                                                            tempStep.selectedOption ===
                                                            idOption
                                                        ) {
                                                            tempStep.selectedOption =
                                                                null;
                                                        }
                                                    }
                                                );
                                                dispatch(updateStep(newStep));
                                            }}
                                        >
                                            <DeleteRoundedIcon fontSize="inherit" />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.optionClassifierContainer}>
                                <div className={styles.classifierNameLbl}>
                                    Clasificador:
                                </div>
                                <div className={styles.optionClassifierInput}>
                                    <RoundedClassifierPicker
                                        fullWidth
                                        alwaysHot
                                        height="32px"
                                        label=""
                                        value={
                                            option.classifier
                                                ? [option.classifier]
                                                : []
                                        }
                                        handleUpdate={(classifiers): void => {
                                            const newStep = produce(
                                                step,
                                                (tempStep) => {
                                                    tempStep.options[
                                                        idOption
                                                    ].classifier =
                                                        classifiers?.[0];
                                                }
                                            );
                                            dispatch(updateStep(newStep));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
                {step.optionList.length < 3 && (
                    <div
                        className={styles.addOption}
                        onClick={(): void => {
                            const newStep = produce(step, (tempStep) => {
                                const id = step.id + ';' + nanoid();
                                const newOption = {
                                    id,
                                    label: '',
                                    steps: [],
                                    conditions: [],
                                };
                                tempStep.options[id] = newOption;
                                tempStep.optionList.push(id);
                            });
                            dispatch(updateStep(newStep));
                        }}
                    >
                        <div className={styles.addIconContainer}>
                            <AddRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.addOptionLbL + ' noselect'}>
                            Agregar Opción
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default ButtonsStepComponent;
