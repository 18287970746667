import { createContext, useCallback, useEffect, useMemo } from 'react';
import {
    PayloadEditorActions,
    PayloadEditorContext,
    usePayloadEditorDispatch,
    usePayloadEditorSelector,
} from '../../controllers/PayloadEditorController/PayloadEditorSlice';
import ImageButton from './Images/ImageButton';
import TextEditor, { TextEditorProps } from './TextEditor';
import { retrieveImageFromClipboardAsBlob } from '../../utils/FileFunctions';
import Attachments from './Attachments/Attachments';
import React from 'react';
import { Payload } from '../../@Types/Payload';
import TemplatesButton from './Templates/TemplatesButton';
import { PayloadEditorTemplateContext } from '../../controllers/PayloadEditorController/PayloadEditorTypes';
import TextEditorContext from './Context/TextEditorContext';
import ActionTypes from '../../constants/ActionTypes';
import { UserTypes } from '../../constants/UserTypes';
import { Concept } from '../../@Types/Project';
import { DraftContext } from '../../@Types/Draft/DraftContext';
import { DraftMappings } from '../../@Types/Draft/DraftMappings';
import EntityEditor from './EntityEditor/EntityEditor';
import { EditableEurekaDecorator } from './EurekaDecorators/EurekaDecorator';
import ConditionTypes from '../../constants/Conditions/ConditionTypes';
import { ConditionContext } from '../ConditionEditor/ConditionEditor';
import { checkAdmin } from '../../utils/PermissionsFunctions';
import { useAppSelector } from '../../hooks';
import AIButton from './AI/AIButton';
import { EurekaDraft } from '../../@Types/Draft/Draft';

export const BasePayloadEditorConditionTypes = [
    ConditionTypes.CLASSIFIER,
    ConditionTypes.TEXT,
    ConditionTypes.CLIENT,
    ConditionTypes.SENTIMENT,
    ConditionTypes.ENTITYVALUE,
    ConditionTypes.COMPANY,
];

export interface PayloadEditorContext {
    organization?: boolean;
    ticket?: boolean;
    /** Should also show project entities and client info*/
    client?: boolean;
    action?: ActionTypes;
    agent?: boolean;
    userType?: UserTypes;
}

export interface PayloadEditorMappings {
    /** Record of idEntity - publicSteps */
    entities?: string[];
    // whatsapp?: ConversationStep[];
    concepts?: Concept[];
    agent?: boolean;
}
export interface PayloadEditorConditions {
    types: ConditionTypes[];
    context?: ConditionContext;
}

interface PayloadEditorProps
    extends Omit<TextEditorProps, 'onChange' | 'editorState' | 'onSubmit'> {
    idEditor: string;
    payload?: Payload;
    media?: boolean;
    disableUnmount?: boolean;
    templates?: PayloadEditorTemplateContext;
    onSubmit?: (payload: Payload) => Promise<boolean | void>;
    generateAI?: (draft: EurekaDraft) => Promise<EurekaDraft>;
    onChange?: (payload: Payload) => void;
    /** The context to allow */
    context?: DraftContext;
    mappings?: DraftMappings;
    conditions?: PayloadEditorConditions;
    /** If entities are allowed in the sent payload */
    allowEntities?: boolean;
    maxEntities?: number;
}

function PayloadEditorComponent({
    payload,
    idEditor,
    disableUnmount,
    templates,
    onSubmit,
    nested,
    context,
    mappings,
    generateAI,
    maxEntities,
    media = true,
    zIndex = 1301,
    conditions = { types: BasePayloadEditorConditionTypes },
    allowEntities = true,
    ...props
}: PayloadEditorProps): JSX.Element {
    const dispatch = usePayloadEditorDispatch();
    const user = useAppSelector((state) => state.site.user);
    const generatingAI = usePayloadEditorSelector(
        (state) => state.generatingAI
    );
    const editorState = usePayloadEditorSelector((state) => state.editorState);
    const preview = usePayloadEditorSelector((state) => state.preview);
    const error = usePayloadEditorSelector((state) => state.hasError);
    const editing = usePayloadEditorSelector((state) => state.editing);

    useEffect(() => {
        if (idEditor) {
            dispatch(
                PayloadEditorActions.setupPayloadEditor({
                    idEditor,
                    payload,
                    templateContext: templates,
                    keepIfExists: disableUnmount,
                })
            );
        }
        return (): void => {
            if (!disableUnmount)
                dispatch(PayloadEditorActions.clearPayloadEditor(idEditor));
        };
    }, [idEditor]);

    const handlePaste = async (event: any): Promise<void> => {
        const focus = await dispatch(
            PayloadEditorActions.isFocused(idEditor) as any
        ).unwrap();
        if (!focus) return;
        retrieveImageFromClipboardAsBlob(event, (blob: any) => {
            if (blob) {
                dispatch(PayloadEditorActions.uploadImage(blob));
            }
        });
    };

    const handleChange = useCallback(async () => {
        if (!props.onChange) return;
        const payload: Payload | null = await dispatch(
            PayloadEditorActions.calcPayload({ idEditor }) as any
        ).unwrap();
        if (!payload) return;
        props.onChange?.(payload);
    }, [props.onChange]);

    useEffect(
        () => {
            handleChange();
        },
        props.onChange ? [editorState] : []
    );

    useEffect((): any => {
        if (!media) return;
        document.addEventListener('paste', handlePaste);
        return (): void => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);

    const handleSubmit = useCallback(
        async (callback: () => void) => {
            try {
                const payload: Payload | null = await dispatch(
                    PayloadEditorActions.calcPayload({
                        idEditor,
                        allowEntities,
                        required: props.required,
                    }) as any
                ).unwrap();
                if (!payload) return;
                const done = await onSubmit?.(payload);
                if (done !== false) {
                    dispatch(PayloadEditorActions.reset());
                    callback();
                }
                return done;
            } catch (error) {
                const failedEntities = error as string[];
                if (failedEntities.length > 0) {
                    const first = failedEntities[0];
                    document.getElementById(first)?.scrollIntoView({
                        block: 'start',
                        inline: 'nearest',
                        behavior: 'smooth',
                    });
                }
            }
        },
        [onSubmit]
    );

    const onChange = useCallback(
        (editorState): void => {
            dispatch(PayloadEditorActions.updateEditorState(editorState));
        },
        [dispatch]
    );

    const customButtons = useMemo(
        () => (): JSX.Element[] => {
            const buttons = media
                ? [
                      <Attachments key="Attachments" />,
                      <ImageButton key="ImageButton" />,
                  ]
                : [];
            if (templates)
                buttons.push(
                    <TemplatesButton zIndex={zIndex} key="TemplatesButton" />
                );
            if (context)
                buttons.push(
                    <TextEditorContext
                        key="TextEditorContext"
                        zIndex={zIndex}
                        nested={nested}
                        context={context}
                        mappings={mappings}
                        maxEntities={maxEntities}
                    />
                );
            if (checkAdmin(user) && generateAI) {
                buttons.push(<AIButton generate={generateAI} key="AIButton" />);
            }
            if (props.customButtons) buttons.push(...props.customButtons());
            return buttons;
        },
        [templates, props.customButtons, media, user, generateAI]
    );

    const customDecorators = useMemo(
        () => (context || mappings ? EditableEurekaDecorator : undefined),
        [context, mappings]
    );

    return (
        <>
            {editing && (
                <EntityEditor
                    conditions={conditions}
                    data={editing.data}
                    context={context}
                    mappings={mappings}
                />
            )}
            <TextEditor
                {...props}
                alwaysScroll
                customDecorator={customDecorators}
                loader={preview?.loading || generatingAI}
                error={error}
                editable={preview === undefined}
                disabled={!!preview?.loading || !!editing}
                editorState={editorState}
                onChange={onChange}
                onSubmit={onSubmit ? handleSubmit : undefined}
                customButtons={customButtons}
            />
        </>
    );
}

export const PayloadEditorConceptsContext = createContext<Concept[]>([]);

function PayloadEditor(props: PayloadEditorProps): JSX.Element {
    return (
        <PayloadEditorConceptsContext.Provider
            value={props.mappings?.concepts ?? []}
        >
            <PayloadEditorContext.Provider value={props.idEditor}>
                <PayloadEditorComponent {...props} />
            </PayloadEditorContext.Provider>
        </PayloadEditorConceptsContext.Provider>
    );
}

export default PayloadEditor;
