import { fromConstToValue, fromValueToConst } from '../StepFunctions';
import Toggle from '../../Toggle/Toggle';
import Slider from '../../Slider/Slider';
import styles from './Step.module.css';
import React, { useState } from 'react';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import RoundedButton from '../../RoundedButton/RoundedButton';
import { updateStepId } from '../../../controllers/GenericEditorController/GenericEditorActions';
import { GBaseStep } from '../../../@Types/GenericForm';
import EntityPropertyTypes from '../../../constants/Entities/EntityPropertyTypes';
import {
    GLocation,
    SectionLocation,
} from '../../../@Types/FormTypes/LocationTypes';
import {
    useGenericEditorDispatch,
    useGenericEditorSelector,
} from '../GenericFormEditorHooks';

interface BottomContainerProps<S extends GBaseStep, L extends GLocation> {
    step: S;
    updateStep: (step: S) => void;
    location: L | SectionLocation;
    editId: boolean;
}
function BottomContainer<S extends GBaseStep, L extends GLocation>({
    step,
    updateStep,
    location,
    editId,
}: BottomContainerProps<S, L>): JSX.Element {
    const [id, setId] = useState(step.id);
    const [error, setError] = useState('');
    const dispatch = useGenericEditorDispatch();
    const existing = useGenericEditorSelector(
        (state) => state.steps[id] !== undefined
    );

    return (
        <React.Fragment>
            {editId && (
                <div className={styles.idEditor}>
                    <div className={styles.idContainer + ' noselect'}>ID:</div>
                    <div className={styles.idInputContainer}>
                        <RoundedTextField
                            value={id}
                            required={true}
                            onChange={(event): void => {
                                setId(event.target.value);
                                if (event.target.value === '') {
                                    setError('Este campo es obligatorio');
                                } else {
                                    setError('');
                                }
                            }}
                            fontWeight={400}
                            backgroundColor="var(--primary1)"
                            helperText={error}
                            error={error != ''}
                        ></RoundedTextField>
                    </div>
                    {id !== step.id && (
                        <div className={styles.changeIdContainer}>
                            <RoundedButton
                                text="Cambiar"
                                onClick={(): void => {
                                    if (existing) {
                                        setError(
                                            'El identificador debe ser único'
                                        );
                                    } else {
                                        dispatch(
                                            updateStepId({
                                                idStep: step.id,
                                                newId: id,
                                                location,
                                            })
                                        );
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className={styles.bottomContainer}>
                <div className={styles.bottonContentContainer}>
                    {(step as any).required !== undefined && (
                        <div className={styles.propContainer}>
                            <div className={styles.propLabel + ' noselect'}>
                                Obligatorio:
                            </div>
                            <div className={styles.toggleContainer}>
                                <Toggle
                                    checked={(step as any).required}
                                    onChange={(checked: boolean): void => {
                                        updateStep({
                                            ...step,
                                            required: checked,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {(step as any).editable !== undefined && (
                        <div className={styles.propContainer}>
                            <div className={styles.propLabel + ' noselect'}>
                                Editable:
                            </div>
                            <div className={styles.toggleContainer}>
                                <Toggle
                                    checked={(step as any).editable}
                                    onChange={(checked: boolean): void => {
                                        updateStep({
                                            ...step,
                                            editable: checked,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {(step as any).showIcon !== undefined && (
                        <div className={styles.propContainer}>
                            <div className={styles.propLabel + ' noselect'}>
                                Mostrar Icono:
                            </div>
                            <div className={styles.toggleContainer}>
                                <Toggle
                                    checked={(step as any).showIcon}
                                    onChange={(checked: boolean): void => {
                                        updateStep({
                                            ...step,
                                            showIcon: checked,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {(step as any).searchable !== undefined && (
                        <div className={styles.propContainer}>
                            <div className={styles.propLabel + ' noselect'}>
                                Busqueda:
                            </div>
                            <div className={styles.toggleContainer}>
                                <Toggle
                                    checked={(step as any).searchable}
                                    onChange={(checked: boolean): void => {
                                        updateStep({
                                            ...step,
                                            searchable: checked,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {(step as any).multiple !== undefined &&
                        step.type !==
                            EntityPropertyTypes.ENTITY_RELATIONSHIP && (
                            <div className={styles.propContainer}>
                                <div className={styles.propLabel + ' noselect'}>
                                    Multiples:
                                </div>
                                <div className={styles.toggleContainer}>
                                    <Toggle
                                        checked={(step as any).multiple}
                                        onChange={(checked: boolean): void => {
                                            updateStep({
                                                ...step,
                                                multiple: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    {(step as any).size !== undefined && (
                        <div className={styles.propContainer}>
                            <div className={styles.propLabel + ' noselect'}>
                                Tamaño:
                            </div>
                            <div className={styles.sliderContainer}>
                                <Slider
                                    onChangeCommitted={(
                                        _e: any,
                                        value: any
                                    ): void => {
                                        updateStep({
                                            ...step,
                                            size: fromValueToConst(value),
                                        });
                                    }}
                                    marks={[
                                        {
                                            value: 0,
                                            label: 'pequeño',
                                        },
                                        {
                                            value: 50,
                                            label: 'mediano',
                                        },
                                        {
                                            value: 100,
                                            label: 'grande',
                                        },
                                    ]}
                                    value={fromConstToValue((step as any).size)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default BottomContainer;
